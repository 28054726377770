import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import {
  DocumentSection,
  DocumentTemplate,
} from "../../../../types/DocumentTemplate";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import React, { useEffect, useState } from "react";
import ContentFormatCard from "./ContentFormatCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  selectDocumentMasterState,
  selectSectionsDocumentMasterState,
} from "../../header/documentMasterSlice";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  headerFooterSection,
  renderContentFormatCards,
  updateSetting,
} from "./Utils";
import SectionPageSetting from "./PageSettings";
import DocumentPageSetting from "./../../header/DocumentFormatMaster/DocumentPageSetting";
import { updateSectionDocumentMaster } from "../../../../app/actions";
import ContentFormattingMaster from "../../header/DocumentFormatMaster/ContentFormattingMaster";
import { useSnackbar } from "notistack";

export default function SectionSettingConfigForm(props: {
  documentTemplate: DocumentTemplate;
  section: DocumentSection;
  closeDialogHandler: any;
}) {
  const documentMasterState = useAppSelector(selectDocumentMasterState);
  const sectionMasterState = useAppSelector(selectSectionsDocumentMasterState);
  const formatting =
    JSON.parse(sectionMasterState[props.section.id]) ??
    documentMasterState.documentMaster;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const actions = useActions();
  const [isGridCollapsed, setIsGridCollapsed] = React.useState(true);
  const [isHeadingCollapsed, setIsHeadingCollapsed] = React.useState(true);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = React.useState(
    formatting?.headerLine
  );
  const [isFooterCollapsed, setIsFooterCollapsed] = React.useState(
    formatting?.footerLine
  );
  const [isFollowDTM, setIsFollowDTM] = React.useState(
    formatting.followDTM ?? true
  );
  const [numberingSelection, setNumberingSelection] = React.useState(
    formatting.numberingSetting ?? "CN"
  );

  const [applyPreFormatting, setApplyPreFormatting] = React.useState(
    formatting.previousSettings
  );

  const generalHeadingFormatting = JSON.parse(
    formatting.general_heading_settings
  );

  const applySectionFormatting = !isFollowDTM && !applyPreFormatting;

  const [createNewSection, setcreateNewSection] = useState(
    !isFollowDTM && !applyPreFormatting ? true : false
  );

  const [generalHeadingSettings, setGeneralHeadingSettings] = React.useState([
    {
      id: 1,
      title: "General Heading Settings",
      topMargin: generalHeadingFormatting.topMargin,
      bottomMargin: generalHeadingFormatting.bottomMargin,
      showColorPicker: false,
      fontColor: generalHeadingFormatting.fontColor,
      font: generalHeadingFormatting.font,
      fontSize: generalHeadingFormatting.fontSize,
      alignment: generalHeadingFormatting.alignment,
      fontStyle: generalHeadingFormatting.fontStyle,
      indent: generalHeadingFormatting.indent,
      format: generalHeadingFormatting.format,
      separator: generalHeadingFormatting.separator,
      prefix: generalHeadingFormatting.prefix,
      suffix: generalHeadingFormatting.suffix,
      compound: generalHeadingFormatting.compound,
      numbering: generalHeadingFormatting.numbering,
      is_hidden: generalHeadingFormatting.is_hidden,
    },
  ]);

  const paragraph1Formatting = JSON.parse(formatting.paragraph1_formatting);
  const paragraph2Formatting = JSON.parse(formatting.paragraph2_formatting);
  const paragraph3Formatting = JSON.parse(formatting.paragraph3_formatting);
  const paragraph4Formatting = JSON.parse(formatting.paragraph4_formatting);
  const paragraph5Formatting = JSON.parse(formatting.paragraph5_formatting);
  const paragraph6Formatting = JSON.parse(formatting.paragraph6_formatting);

  const [paragraphSettings, setParagraphSettings] = React.useState([
    {
      id: 1,
      title: "Title Page Paragraph",
      topMargin: paragraph1Formatting.topMargin,
      bottomMargin: paragraph1Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph1Formatting.fontColor,
      font: paragraph1Formatting.font,
      fontSize: paragraph1Formatting.fontSize,
      alignment: paragraph1Formatting.alignment,
      fontStyle: paragraph1Formatting.fontStyle,
      indent: paragraph1Formatting.indent,
      format: paragraph1Formatting.format,
      separator: paragraph1Formatting.separator,
      prefix: paragraph1Formatting.prefix,
      suffix: paragraph1Formatting.suffix,
      compound: paragraph1Formatting.compound,
      numbering: paragraph1Formatting.numbering,
      is_hidden: paragraph1Formatting.is_hidden,
      linespacing: paragraph1Formatting.linespacing,
    },
    {
      id: 2,
      title: "Paragraph 2",
      topMargin: paragraph2Formatting.topMargin,
      bottomMargin: paragraph2Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph2Formatting.fontColor,
      font: paragraph2Formatting.font,
      fontSize: paragraph2Formatting.fontSize,
      alignment: paragraph2Formatting.alignment,
      fontStyle: paragraph2Formatting.fontStyle,
      indent: paragraph2Formatting.indent,
      format: paragraph2Formatting.format,
      separator: paragraph2Formatting.separator,
      prefix: paragraph2Formatting.prefix,
      suffix: paragraph2Formatting.suffix,
      compound: paragraph2Formatting.compound,
      numbering: paragraph2Formatting.numbering,
      is_hidden: paragraph2Formatting.is_hidden,
      linespacing: paragraph2Formatting.linespacing,
    },
    {
      id: 3,
      title: "Paragraph 3",
      topMargin: paragraph3Formatting.topMargin,
      bottomMargin: paragraph3Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph3Formatting.fontColor,
      font: paragraph3Formatting.font,
      fontSize: paragraph3Formatting.fontSize,
      alignment: paragraph3Formatting.alignment,
      fontStyle: paragraph3Formatting.fontStyle,
      indent: paragraph3Formatting.indent,
      format: paragraph3Formatting.format,
      separator: paragraph3Formatting.separator,
      prefix: paragraph3Formatting.prefix,
      suffix: paragraph3Formatting.suffix,
      compound: paragraph3Formatting.compound,
      numbering: paragraph3Formatting.numbering,
      is_hidden: paragraph3Formatting.is_hidden,
      linespacing: paragraph3Formatting.linespacing,
    },
    {
      id: 4,
      title: "Paragraph 4",
      topMargin: paragraph4Formatting.topMargin,
      bottomMargin: paragraph4Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph4Formatting.fontColor,
      font: paragraph4Formatting.font,
      fontSize: paragraph4Formatting.fontSize,
      alignment: paragraph4Formatting.alignment,
      fontStyle: paragraph4Formatting.fontStyle,
      indent: paragraph4Formatting.indent,
      format: paragraph4Formatting.format,
      separator: paragraph4Formatting.separator,
      prefix: paragraph4Formatting.prefix,
      suffix: paragraph4Formatting.suffix,
      compound: paragraph4Formatting.compound,
      numbering: paragraph4Formatting.numbering,
      is_hidden: paragraph4Formatting.is_hidden,
      linespacing: paragraph4Formatting.linespacing,
    },
    {
      id: 5,
      title: "Paragraph 5",
      topMargin: paragraph5Formatting.topMargin,
      bottomMargin: paragraph5Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph5Formatting.fontColor,
      font: paragraph5Formatting.font,
      fontSize: paragraph5Formatting.fontSize,
      alignment: paragraph5Formatting.alignment,
      fontStyle: paragraph5Formatting.fontStyle,
      indent: paragraph5Formatting.indent,
      format: paragraph5Formatting.format,
      separator: paragraph5Formatting.separator,
      prefix: paragraph5Formatting.prefix,
      suffix: paragraph5Formatting.suffix,
      compound: paragraph5Formatting.compound,
      numbering: paragraph5Formatting.numbering,
      is_hidden: paragraph5Formatting.is_hidden,
      linespacing: paragraph5Formatting.linespacing,
    },
    {
      id: 6,
      title: "Paragraph 6",
      topMargin: paragraph6Formatting.topMargin,
      bottomMargin: paragraph6Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: paragraph6Formatting.fontColor,
      font: paragraph6Formatting.font,
      fontSize: paragraph6Formatting.fontSize,
      alignment: paragraph6Formatting.alignment,
      fontStyle: paragraph6Formatting.fontStyle,
      indent: paragraph6Formatting.indent,
      format: paragraph6Formatting.format,
      separator: paragraph6Formatting.separator,
      prefix: paragraph6Formatting.prefix,
      suffix: paragraph6Formatting.suffix,
      compound: paragraph6Formatting.compound,
      numbering: paragraph6Formatting.numbering,
      is_hidden: paragraph6Formatting.is_hidden,
      linespacing: paragraph6Formatting.linespacing,
    },
  ]);

  const generalParagraphFormatting = JSON.parse(
    formatting.general_paragraph_settings
  );

  const [generalParagraphSettings, setGeneralParagraphSettings] =
    React.useState([
      {
        id: 1,
        title: "General Paragraph Settings",
        topMargin: generalParagraphFormatting.topMargin,
        bottomMargin: generalParagraphFormatting.bottomMargin,
        showColorPicker: false,
        fontColor: generalParagraphFormatting.fontColor,
        font: generalParagraphFormatting.font,
        fontSize: generalParagraphFormatting.fontSize,
        alignment: generalParagraphFormatting.alignment,
        fontStyle: generalParagraphFormatting.fontStyle,
        indent: generalParagraphFormatting.indent,
        format: generalParagraphFormatting.format,
        separator: generalParagraphFormatting.separator,
        prefix: generalParagraphFormatting.prefix,
        suffix: generalParagraphFormatting.suffix,
        compound: generalParagraphFormatting.compound,
        numbering: generalParagraphFormatting.numbering,
        is_hidden: generalParagraphFormatting.is_hidden,
        continueHeadingSettings: true,
        linespacing: generalParagraphFormatting.linespacing,
      },
    ]);

  const heading1Formatting = JSON.parse(formatting.heading1_formatting);
  const heading2Formatting = JSON.parse(formatting.heading2_formatting);
  const heading3Formatting = JSON.parse(formatting.heading3_formatting);
  const heading4Formatting = JSON.parse(formatting.heading4_formatting);
  const heading5Formatting = JSON.parse(formatting.heading5_formatting);
  const heading6Formatting = JSON.parse(formatting.heading6_formatting);
  // ! Heading Setting Object

  const [headingSettings, setHeadingSettings] = React.useState([
    {
      id: 1,
      title: "Heading 1",
      topMargin: heading1Formatting.topMargin,
      bottomMargin: heading1Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading1Formatting.fontColor,
      font: heading1Formatting.font,
      fontSize: heading1Formatting.fontSize,
      alignment: heading1Formatting.alignment,
      fontStyle: heading1Formatting.fontStyle,
      indent: heading1Formatting.indent,
      format: heading1Formatting.format,
      prefix: heading1Formatting.prefix,
      suffix: heading1Formatting.suffix,
      compound: heading1Formatting.compound,
      numbering: heading1Formatting.numbering,
      is_hidden: heading1Formatting.is_hidden,
      separator: heading1Formatting.separator,
    },
    {
      id: 2,
      title: "Heading 2",
      topMargin: heading2Formatting.topMargin,
      bottomMargin: heading2Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading2Formatting.fontColor,
      font: heading2Formatting.font,
      fontSize: heading2Formatting.fontSize,
      alignment: heading2Formatting.alignment,
      fontStyle: heading2Formatting.fontStyle,
      indent: heading2Formatting.indent,
      format: heading2Formatting.format,
      separator: heading2Formatting.separator,
      prefix: heading2Formatting.prefix,
      suffix: heading2Formatting.suffix,
      compound: heading2Formatting.compound,
      numbering: heading2Formatting.numbering,
      is_hidden: heading2Formatting.is_hidden,
    },
    {
      id: 3,
      title: "Heading 3",
      topMargin: heading3Formatting.topMargin,
      bottomMargin: heading3Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading3Formatting.fontColor,
      font: heading3Formatting.font,
      fontSize: heading3Formatting.fontSize,
      alignment: heading3Formatting.alignment,
      fontStyle: heading3Formatting.fontStyle,
      indent: heading3Formatting.indent,
      format: heading3Formatting.format,
      separator: heading3Formatting.separator,
      prefix: heading3Formatting.prefix,
      suffix: heading3Formatting.suffix,
      compound: heading3Formatting.compound,
      numbering: heading3Formatting.numbering,
      is_hidden: heading3Formatting.is_hidden,
    },
    {
      id: 4,
      title: "Heading 4",
      topMargin: heading4Formatting.topMargin,
      bottomMargin: heading4Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading4Formatting.fontColor,
      font: heading4Formatting.font,
      fontSize: heading4Formatting.fontSize,
      alignment: heading4Formatting.alignment,
      fontStyle: heading4Formatting.fontStyle,
      indent: heading4Formatting.indent,
      format: heading4Formatting.format,
      separator: heading4Formatting.separator,
      prefix: heading4Formatting.prefix,
      suffix: heading4Formatting.suffix,
      compound: heading4Formatting.compound,
      numbering: heading4Formatting.numbering,
      is_hidden: heading4Formatting.is_hidden,
    },
    {
      id: 5,
      title: "Heading 5",
      topMargin: heading5Formatting.topMargin,
      bottomMargin: heading5Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading5Formatting.fontColor,
      font: heading5Formatting.font,
      fontSize: heading5Formatting.fontSize,
      alignment: heading5Formatting.alignment,
      fontStyle: heading5Formatting.fontStyle,
      indent: heading5Formatting.indent,
      format: heading5Formatting.format,
      separator: heading5Formatting.separator,
      prefix: heading5Formatting.prefix,
      suffix: heading5Formatting.suffix,
      compound: heading5Formatting.compound,
      numbering: heading5Formatting.numbering,
      is_hidden: heading5Formatting.is_hidden,
    },
    {
      id: 6,
      title: "Heading 6",
      topMargin: heading6Formatting.topMargin,
      bottomMargin: heading6Formatting.bottomMargin,
      showColorPicker: false,
      fontColor: heading6Formatting.fontColor,
      font: heading6Formatting.font,
      fontSize: heading6Formatting.fontSize,
      alignment: heading6Formatting.alignment,
      fontStyle: heading6Formatting.fontStyle,
      indent: heading6Formatting.indent,
      format: heading6Formatting.format,
      separator: heading6Formatting.separator,
      prefix: heading6Formatting.prefix,
      suffix: heading6Formatting.suffix,
      compound: heading6Formatting.compound,
      numbering: heading6Formatting.numbering,
      is_hidden: heading6Formatting.is_hidden,
    },
    // Add more heading settings as needed
  ]);

  const [headerChecked, setHeaderChecked] = React.useState(
    formatting.is_header
  );
  const [footerChecked, setFooterChecked] = React.useState(
    formatting.is_footer
  );

  const restSectionFormatting = () => {
    const formatting = documentMasterState.documentMaster;

    const paragraph1Formatting = JSON.parse(formatting.paragraph1_formatting);
    const paragraph2Formatting = JSON.parse(formatting.paragraph2_formatting);
    const paragraph3Formatting = JSON.parse(formatting.paragraph3_formatting);
    const paragraph4Formatting = JSON.parse(formatting.paragraph4_formatting);
    const paragraph5Formatting = JSON.parse(formatting.paragraph5_formatting);
    const paragraph6Formatting = JSON.parse(formatting.paragraph6_formatting);

    const heading1Formatting = JSON.parse(formatting.heading1_formatting);
    const heading2Formatting = JSON.parse(formatting.heading2_formatting);
    const heading3Formatting = JSON.parse(formatting.heading3_formatting);
    const heading4Formatting = JSON.parse(formatting.heading4_formatting);
    const heading5Formatting = JSON.parse(formatting.heading5_formatting);
    const heading6Formatting = JSON.parse(formatting.heading6_formatting);

    setApplyPreFormatting(false);
    setIsFollowDTM(false);

    setHeadingSettings([
      { ...heading1Formatting },
      { ...heading2Formatting },
      { ...heading3Formatting },
      { ...heading4Formatting },
      { ...heading5Formatting },
      { ...heading6Formatting },
    ]);

    setParagraphSettings([
      {
        ...paragraph1Formatting,
      },
      {
        ...paragraph2Formatting,
      },
      {
        ...paragraph3Formatting,
      },
      {
        ...paragraph4Formatting,
      },
      {
        ...paragraph5Formatting,
      },
      {
        ...paragraph6Formatting,
      },
    ]);

    setHeaderSettings([
      {
        id: 1,
        title: "Header Left",
        font: formatting.header_font_style_l,
        fontSize: formatting.header_font_size_l.toString(),
        fontStyle: JSON.parse(formatting.header_styles_l),
        fontColor: formatting.header_styles_l_color,
        selection: formatting.header_selection_l,
        freeText: formatting.header_free_text_l,
        image_Id: formatting.header_image_l,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.header_pageno_l === null
            ? " "
            : formatting.header_pageno_l,
      },
      {
        id: 2,
        title: "Header Center",
        font: formatting.header_font_style_c,
        fontSize: formatting.header_font_size_c.toString(),
        fontStyle: JSON.parse(formatting.header_styles_c),
        fontColor: formatting.header_styles_c_color,
        selection: formatting.header_selection_c,
        freeText: formatting.header_free_text_c,
        image_Id: formatting.header_image_c,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.header_pageno_c === null
            ? " "
            : formatting.header_pageno_c,
      },
      {
        id: 3,
        title: "Header Right",
        font: formatting.header_font_style_r,
        fontSize: formatting.header_font_size_r.toString(),
        fontStyle: JSON.parse(formatting.header_styles_r),
        fontColor: formatting.header_styles_r_color,
        selection: formatting.header_selection_r,
        freeText: formatting.header_free_text_r,
        image_Id: formatting.header_image_r,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.header_pageno_r === null
            ? " "
            : formatting.header_pageno_r,
      },
    ]);

    setFooterSettings([
      {
        id: 1,
        title: "Footer Left",
        font: formatting.footer_font_style_l,
        fontSize: formatting.footer_font_size_l.toString(),
        fontStyle: JSON.parse(formatting.header_styles_l),
        fontColor: formatting.footer_styles_l_color,
        selection: formatting.footer_selection_l,
        freeText: formatting.footer_free_text_l,
        image_Id: formatting.footer_image_l,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.header_pageno_l === null
            ? " "
            : formatting.header_pageno_l,
      },
      {
        id: 2,
        title: "Footer Center",
        font: formatting.header_font_style_c,
        fontSize: formatting.header_font_size_c.toString(),
        fontStyle: JSON.parse(formatting.footer_styles_c),
        fontColor: formatting.footer_styles_c_color,
        selection: formatting.footer_selection_c,
        freeText: formatting.footer_free_text_c,
        image_Id: formatting.footer_image_c,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.footer_pageno_c === null
            ? " "
            : formatting.footer_pageno_c,
      },
      {
        id: 3,
        title: "Footer Right",
        font: formatting.footer_font_style_r,
        fontSize: formatting.footer_font_size_r.toString(),
        fontStyle: JSON.parse(formatting.footer_styles_r),
        fontColor: formatting.footer_styles_r_color,
        selection: formatting.footer_selection_r,
        freeText: formatting.footer_free_text_r,
        image_Id: formatting.footer_image_r,
        pageNumberStyle: formatting.page_number_style,
        isPageNumberOnFirst: false,
        pageStyleText:
          formatting.footer_pageno_r === null
            ? " "
            : formatting.footer_pageno_r,
      },
    ]);

    setPageSettings([
      {
        id: 1,
        title: "Page Settings",
        pageTopMargin: formatting.page_margin_top,
        pageBottomMargin: formatting.page_margin_bottom,
        pageLeftMargin: formatting.page_margin_left,
        pageRightMargin: formatting.page_margin_right,
        pageWidth: formatting.page_width,
        pageHeight: formatting.page_height,
        pageColumn: formatting.page_columns,
        pageSize:
          formatting.custom === "true" ? "custom" : formatting.page_type,
        pageColor: formatting.page_background_color,
        pageOrientation: formatting.orientation,
        headerUnderline: formatting.header_line,
        footerUnderline: formatting.footer_line,
        raggedColumn: formatting.ragged_column,
        defaultIndent: formatting.default_indent,
      },
    ]);

    const generalParagraphFormatting = JSON.parse(
      formatting.general_paragraph_settings
    );
    const generalHeadingFormatting = JSON.parse(
      formatting.general_heading_settings
    );

    setGeneralParagraphSettings([
      {
        ...generalParagraphFormatting,
      },
    ]);

    setGeneralHeadingSettings([
      {
        ...generalHeadingFormatting,
      },
    ]);

    setFooterChecked(formatting.is_footer);
    setHeaderChecked(formatting.is_header);

    enqueueSnackbar(
      "Settings successfully reset to Document Template Formatting!",
      {
        variant: "success", // Use the 'error' variant for red color
      }
    );
  };

  // const [headerSettings, setHeaderSettings] = React.useState([
  //   {
  //     id: 1,
  //     title: "Header Left",
  //     font: formatting.header_font_style_l,
  //     fontSize:
  //       formatting.header_font_size_l.toString(),
  //     fontStyle: JSON.parse(formatting.header_styles_l),
  //     fontColor: formatting.header_styles_l_color,
  //     selection: formatting.header_selection_l,
  //     freeText: formatting.header_free_text_l,
  //     image_Id: formatting.header_image_l,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.header_pageno_l === null
  //         ? " "
  //         : formatting.header_pageno_l,
  //   },
  //   {
  //     id: 2,
  //     title: "Header Center",
  //     font: formatting.header_font_style_c,
  //     fontSize:
  //       formatting.header_font_size_c.toString(),
  //     fontStyle: JSON.parse(formatting.header_styles_c),
  //     fontColor: formatting.header_styles_c_color,
  //     selection: formatting.header_selection_c,
  //     freeText: formatting.header_free_text_c,
  //     image_Id: formatting.header_image_c,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.header_pageno_c === null
  //         ? " "
  //         : formatting.header_pageno_c,
  //   },
  //   {
  //     id: 3,
  //     title: "Header Right",
  //     font: formatting.header_font_style_r,
  //     fontSize:
  //       formatting.header_font_size_r.toString(),
  //     fontStyle: JSON.parse(formatting.header_styles_r),
  //     fontColor: formatting.header_styles_r_color,
  //     selection: formatting.header_selection_r,
  //     freeText: formatting.header_free_text_r,
  //     image_Id: formatting.header_image_r,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.header_pageno_r === null
  //         ? " "
  //         : formatting.header_pageno_r,
  //   },
  // ]);

  const footerLeft = JSON.parse(formatting.footerLeft);
  const footerCenter = JSON.parse(formatting.footerCenter);

  const footerRight = JSON.parse(formatting.footerRight);

  const headerLeft = JSON.parse(formatting.headerLeft);

  const headerCenter = JSON.parse(formatting.headerCenter);

  const headerRight = JSON.parse(formatting.headerRight);
  const pageFormatting = JSON.parse(formatting.pageSettings);

  const [headerSettings, setHeaderSettings] = React.useState([
    {
      ...headerLeft,
    },
    {
      ...headerCenter,
    },
    {
      ...headerRight,
    },
  ]);

  const [footerSettings, setFooterSettings] = React.useState([
    {
      ...footerLeft,
    },
    {
      ...footerCenter,
    },
    {
      ...footerRight,
    },
  ]);

  // const [footerSettings, setFooterSettings] = React.useState([...
  //   {
  //     id: 4,
  //     title: "Footer Left",
  //     font: formatting.footer_font_style_l,
  //     fontSize:
  //       formatting.footer_font_size_l.toString(),
  //     fontStyle: JSON.parse(formatting.footer_styles_l),
  //     fontColor: formatting.footer_styles_l_color,
  //     selection: formatting.footer_selection_l,
  //     freeText: formatting.footer_free_text_l,
  //     image_Id: formatting.footer_image_l,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.footer_pageno_l === null
  //         ? " "
  //         : formatting.footer_pageno_l,
  //   },
  //   {
  //     id: 5,
  //     title: "Footer Center",
  //     font: formatting.footer_font_style_c,
  //     fontSize:
  //       formatting.footer_font_size_c.toString(),
  //     fontStyle: JSON.parse(formatting.footer_styles_c),
  //     fontColor: formatting.footer_styles_c_color,
  //     selection: formatting.footer_selection_c,
  //     freeText: formatting.footer_free_text_c,
  //     image_Id: formatting.footer_image_c,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.footer_pageno_c === null
  //         ? " "
  //         : formatting.footer_pageno_c,
  //   },
  //   {
  //     id: 6,
  //     title: "Footer Right",
  //     font: formatting.footer_font_style_r,
  //     fontSize:
  //       formatting.footer_font_size_r.toString(),
  //     fontStyle: JSON.parse(formatting.footer_styles_r),
  //     fontColor: formatting.footer_styles_r_color,
  //     selection: formatting.footer_selection_r,
  //     freeText: formatting.footer_free_text_r,
  //     image_Id: formatting.footer_image_r,
  //     pageNumberStyle: formatting.page_number_style,
  //     isPageNumberOnFirst: false,
  //     pageStyleText:
  //       formatting.footer_pageno_r === null
  //         ? " "
  //         : formatting.footer_pageno_r,
  //   },
  // ]);

  const [pageSettings, setPageSettings] = React.useState([
    {
      ...pageFormatting,
    },
  ]);

  // const [pageSettings, setPageSettings] = React.useState([
  //   {
  //     id: 1,
  //     title: "Page Settings",
  //     pageTopMargin: formatting.page_margin_top,
  //     pageBottomMargin: formatting.page_margin_bottom,
  //     pageLeftMargin: formatting.page_margin_left,
  //     pageRightMargin: formatting.page_margin_right,
  //     pageWidth: formatting.page_width,
  //     pageHeight: formatting.page_height,
  //     pageColumn: formatting.page_columns,
  //     pageSize:
  //       formatting.custom === "true"
  //         ? "custom"
  //         : formatting.page_type,
  //     pageColor: formatting.page_background_color,
  //     pageOrientation: formatting.orientation,
  //     headerUnderline: formatting.header_line,
  //     footerUnderline: formatting.footer_line,
  //     raggedColumn: formatting.ragged_column,
  //     defaultIndent: formatting.default_indent,
  //   },
  // ]);

  const updatePageSetting = (
    index: number,
    field: string,
    value: any,
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setter((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        [field]: value,
      };
      return updatedSettings;
    });
  };

  const handleClose = () => {
    // actions.getSectionDocumentMaster()
    props.closeDialogHandler();
  };

  const updateHeaderSetting = (index: number, field: string, value: any) => {
    setHeaderSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        [field]: value,
      };
      return updatedSettings;
    });
  };

  const updateFooterSetting = (index: number, field: string, value: any) => {
    setFooterSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index] = {
        ...updatedSettings[index],
        [field]: value,
      };
      return updatedSettings;
    });
  };

  const object = Object.assign({
    followDTM: true,
    headerLine: true,
    footerLine: true,
    numberingSetting: "CN",
    previousSettings: false,
    is_header: false,
    is_footer: false,
    default_indent: false,
    headerLeft:
      '{"id":1,"title":"Header Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    headerCenter:
      '{"id":2,"title":"Header Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    headerRight:
      '{"id":3,"title":"Header Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    footerLeft:
      '{"id":1,"title":"Footer Left","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    footerCenter:
      '{"id":2,"title":"Footer Center","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    footerRight:
      '{"id":3,"title":"Footer Right","font":"arial","fontSize":"12","fontStyle":[],"fontColor":"000000","selection":"","freeText":null,"image_Id":null,"pageNumberStyle":"arabic","isPageNumberOnFirst":false,"pageStyleText":null}',
    pageSettings:
      '{"id":1,"title":"Page Settings","pageTopMargin":"25.54","pageBottomMargin":"25.54","pageLeftMargin":25.54,"pageRightMargin":25.54,"pageWidth":"210","pageHeight":"297","pageColumn":1,"pageSize":"A4","pageColor":"FFFFFF","pageOrientation":"portrait","headerUnderline":false,"footerUnderline":false,"raggedColumn":true,"defaultIndent":false}',
    general_heading_settings:
      '{"id":1,"title":"General Heading Settings","topMargin":"0","bottomMargin":"12","showColorPicker":false,"fontColor":"020202","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":[],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true}',
    general_paragraph_settings:
      '{"id":1,"title":"General Paragraph Settings","topMargin":"0","bottomMargin":"4","showColorPicker":false,"fontColor":"070707","font":"arial","fontSize":"12","alignment":"raggedright","fontStyle":["bold",""],"indent":0,"format":"arabic","separator":".","prefix":"(","suffix":")","compound":true,"continueHeadingSettings":true,"linespacing":"1"}',
  });

  const handleSave = () => {
    // actions.getSectionDocumentMaster();
    const object = Object.assign({
      //  the imported document Master
      // Template Master Name
      //Header
      followDTM: isFollowDTM,
      numberingSetting: numberingSelection,
      previousSettings: applyPreFormatting,
      is_header: headerChecked,
      is_footer: footerChecked,
      headerLine: isHeaderCollapsed,
      footerLine: isFooterCollapsed,
      default_indent: pageSettings[0].defaultIndent,
      header_styles_l: JSON.stringify(headerSettings[0].fontStyle),
      header_styles_r: JSON.stringify(headerSettings[2].fontStyle),
      header_styles_c: JSON.stringify(headerSettings[1].fontStyle),
      footer_styles_l: JSON.stringify(footerSettings[0].fontStyle),
      footer_styles_r: JSON.stringify(footerSettings[2].fontStyle),
      footer_styles_c: JSON.stringify(footerSettings[1].fontStyle),

      headerLeft: JSON.stringify({
        ...headerSettings[0],
        pageStyleText:
          headerSettings[0].selection === "Page Number"
            ? headerSettings[0].pageStyleText
            : null,
        image_Id:
          headerSettings[0].selection === "image"
            ? headerSettings[0].image_Id
            : null,
        freeText:
          headerSettings[0].selection === "none"
            ? null
            : headerSettings[0].freeText,
      }),

      headerCenter: JSON.stringify({
        ...headerSettings[1],
        pageStyleText:
          headerSettings[1].selection === "Page Number"
            ? headerSettings[1].pageStyleText
            : null,
        image_Id:
          headerSettings[1].selection === "image"
            ? headerSettings[1].image_Id
            : null,
        freeText:
          headerSettings[1].selection === "none"
            ? null
            : headerSettings[1].freeText,
      }),

      headerRight: JSON.stringify({
        ...headerSettings[2],
        pageStyleText:
          headerSettings[2].selection === "Page Number"
            ? headerSettings[2].pageStyleText
            : null,
        image_Id:
          headerSettings[2].selection === "image"
            ? headerSettings[2].image_Id
            : null,
        freeText:
          headerSettings[2].selection === "none"
            ? null
            : headerSettings[2].freeText,
      }),

      footerLeft: JSON.stringify({
        ...footerSettings[0],
        pageStyleText:
          footerSettings[0].selection === "Page Number"
            ? footerSettings[0].pageStyleText
            : null,
        image_Id:
          footerSettings[0].selection === "image"
            ? footerSettings[0].image_Id
            : null,
        freeText:
          footerSettings[0].selection === "none"
            ? null
            : footerSettings[0].freeText,
      }),

      footerCenter: JSON.stringify({
        ...footerSettings[1],
        pageStyleText:
          footerSettings[1].selection === "Page Number"
            ? footerSettings[1].pageStyleText
            : null,
        image_Id:
          footerSettings[1].selection === "image"
            ? footerSettings[1].image_Id
            : null,
        freeText:
          footerSettings[1].selection === "none"
            ? null
            : footerSettings[1].freeText,
      }),

      footerRight: JSON.stringify({
        ...footerSettings[2],
        pageStyleText:
          footerSettings[2].selection === "Page Number"
            ? footerSettings[2].pageStyleText
            : null,
        image_Id:
          footerSettings[2].selection === "image"
            ? footerSettings[2].image_Id
            : null,
        freeText:
          footerSettings[2].selection === "none"
            ? null
            : footerSettings[2].freeText,
      }),
      pageSettings: JSON.stringify(pageSettings[0]),
      // General Settings
      general_heading_settings: JSON.stringify(generalHeadingSettings[0]),
      general_paragraph_settings: JSON.stringify(generalParagraphSettings[0]),

      //Headings
      heading1_formatting: JSON.stringify(headingSettings[0]),
      heading2_formatting: JSON.stringify(headingSettings[1]),
      heading3_formatting: JSON.stringify(headingSettings[2]),
      heading4_formatting: JSON.stringify(headingSettings[3]),
      heading5_formatting: JSON.stringify(headingSettings[4]),
      heading6_formatting: JSON.stringify(headingSettings[5]),

      //Paragraphs
      paragraph1_formatting: JSON.stringify(paragraphSettings[0]),
      paragraph2_formatting: JSON.stringify(paragraphSettings[1]),
      paragraph3_formatting: JSON.stringify(paragraphSettings[2]),
      paragraph4_formatting: JSON.stringify(paragraphSettings[3]),
      paragraph5_formatting: JSON.stringify(paragraphSettings[4]),
      paragraph6_formatting: JSON.stringify(paragraphSettings[5]),
      // extra
    });

    actions.updateSectionDocumentMaster({
      DocumentMaster: object,
      documentSectionId: props.section.id,
    });

    // dispatch(
    //   updateSectionDocumentMaster({
    //     documentSectionId: props.section.id,
    //     DocumentMaster: object,
    //   })
    // );
    // actions.updateDocumentMaster(object);
    props.closeDialogHandler();
  };

  const updateGeneralHeadingSetting = (
    index: number,
    field: string,
    value: any,
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    // Update each setting in the headingSettings array
    for (let i = 0; i < 6; i++) {
      // updateSetting(index, field, value, setHeadingSettings);

      if (field === "numbering") {
        setHeadingSettings((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[i] = {
            ...updatedSettings[i],
            compound: value === "compound" ? true : false,
            is_hidden: value === "hidden" ? true : false,
            [field]: value,
          };
          return updatedSettings;
        });

        setter((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[index] = {
            ...updatedSettings[index],
            compound: value === "compound" ? true : false,
            is_hidden: value === "hidden" ? true : false,
            [field]: value,
          };
          return updatedSettings;
        });
      } else {
        setHeadingSettings((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[i] = {
            ...updatedSettings[i],
            [field]: value,
          };
          return updatedSettings;
        });

        setter((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[index] = {
            ...updatedSettings[index],
            [field]: value,
          };
          return updatedSettings;
        });
      }
    }
  };

  const updateGeneralParagraphSetting = (
    index: number,
    field: string,
    value: any,
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    // Update each setting in the headingSettings array
    for (let i = 0; i < 6; i++) {
      // updateSetting(index, field, value, setHeadingSettings);

      if (field === "numbering") {
        setParagraphSettings((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[i] = {
            ...updatedSettings[i],
            compound: value === "compound" ? true : false,
            is_hidden: value === "hidden" ? true : false,
            [field]: value,
          };
          return updatedSettings;
        });

        // Update the specific setting at the given index
        setter((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[index] = {
            ...updatedSettings[index],
            compound: value === "compound" ? true : false,
            is_hidden: value === "hidden" ? true : false,
            [field]: value,
          };
          return updatedSettings;
        });
      } else {
        setParagraphSettings((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[i] = {
            ...updatedSettings[i],
            [field]: value,
          };
          return updatedSettings;
        });

        setter((prevSettings) => {
          const updatedSettings = [...prevSettings];
          updatedSettings[index] = {
            ...updatedSettings[index],
            [field]: value,
          };
          return updatedSettings;
        });
      }

      // Update the specific setting at the given index
    }
  };

  const setContinueHeadingSetting = (newValue: any) => {
    const updatedParagraphGeneralSettings = generalHeadingSettings.map(
      (headingSetting) => {
        return {
          ...headingSetting,
          title:
            headingSetting.title === "Heading 1"
              ? "Title Page Paragraph"
              : headingSetting.title.replace("Heading", "Paragraph"),
          linespacing: 1,
          continueHeadingSettings: true,
        };
      }
    );
    setParagraphSettings(updatedParagraphGeneralSettings);

    if (newValue === true) {
      const updatedParagraphSettings = headingSettings.map((headingSetting) => {
        return {
          ...headingSetting,
          title:
            headingSetting.title === "Heading 1"
              ? "Title Page Paragraph"
              : headingSetting.title.replace("Heading", "Paragraph"),
          linespacing: 1,
        };
      });
      setParagraphSettings(updatedParagraphSettings);
    }

    enqueueSnackbar("Successfully applied!", {
      variant: "success", // Use the 'error' variant for red color
    });
  };

  const setDefaultIndent = (value: any) => {
    if (value) {
      updateGeneralHeadingSetting(
        0,
        "compound",
        true,
        setGeneralHeadingSettings
      );
      updateGeneralHeadingSetting(
        0,
        "seperator",
        ".",
        setGeneralHeadingSettings
      );
      updateGeneralHeadingSetting(0, "prefix", "", setGeneralHeadingSettings);
      updateGeneralHeadingSetting(0, "suffix", "", setGeneralHeadingSettings);
      updateGeneralHeadingSetting(
        0,
        "alignment",
        "raggedright",
        setGeneralHeadingSettings
      );
      updateGeneralHeadingSetting(0, "indent", 0, setGeneralHeadingSettings);
      updateGeneralParagraphSetting(
        0,
        "compound",
        true,
        setGeneralParagraphSettings
      );
      updateGeneralParagraphSetting(
        0,
        "seperator",
        ".",
        setGeneralParagraphSettings
      );
      updateGeneralParagraphSetting(
        0,
        "prefix",
        "",
        setGeneralParagraphSettings
      );
      updateGeneralParagraphSetting(
        0,
        "suffix",
        "",
        setGeneralParagraphSettings
      );
      updateGeneralParagraphSetting(
        0,
        "alignment",
        "raggedright",
        setGeneralParagraphSettings
      );
      updateGeneralParagraphSetting(
        0,
        "indent",
        0,
        setGeneralParagraphSettings
      );
    }
  };

  useEffect(() => {
    if (!isFollowDTM && !applyPreFormatting) {
      setcreateNewSection(true);
    }
  }, [isFollowDTM, applyPreFormatting]);

  return (
    <Dialog
      open={true}
      onClose={(e) => {
        props.closeDialogHandler();
      }}
      id="dialogRef" // Add an ID to the Dialog component for reference
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
    >
      <DialogTitle>
        <FormatPaintIcon />
        &nbsp; &nbsp; Section Setting
      </DialogTitle>
      <DialogContent>
        <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
          <Box
            sx={{
              width: "500px",
              display: "flex",
              my: "5px",
              alignItems: "center",
            }}
          >
            <InputLabel>Follow Document Master formatting?</InputLabel>
            <Checkbox
              checked={isFollowDTM}
              onChange={(e) => {
                if (!isFollowDTM) {
                  setIsFollowDTM(e.target.checked);
                  setApplyPreFormatting(false);
                  setcreateNewSection(false);
                }
              }}
            />
          </Box>

          <Box
            sx={{
              width: "500px",
              display: "flex",
              my: "5px",
              alignItems: "center",
            }}
          >
            <InputLabel>Apply formatting of previous section?</InputLabel>
            <Checkbox
              checked={applyPreFormatting}
              onChange={(e) => {
                if (!applyPreFormatting) {
                  setApplyPreFormatting(e.target.checked);
                  setcreateNewSection(false);
                  setIsFollowDTM(false);
                }
              }}
            />
          </Box>

          <Box
            sx={{
              width: "500px",
              display: "flex",
              my: "5px",
              alignItems: "center",
            }}
          >
            <InputLabel>Create new section format master?</InputLabel>
            <Checkbox
              checked={createNewSection}
              onChange={(e) => {
                if (!createNewSection) {
                  setIsFollowDTM(false);
                  setApplyPreFormatting(false);
                  setcreateNewSection(e.target.checked);
                }
              }}
            />
          </Box>

          {createNewSection && (
            <Box sx={{ width: "500px", display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                color="error"
                sx={{ my: "5px", mb: "15px" }}
                onClick={restSectionFormatting}
              >
                Reset to document format master
              </Button>
            </Box>
          )}

          <Box
            sx={{
              width: "500px",
              display: "flex",
              alignItems: "center",
              my: "10px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="table-columns">Numbering Setting</InputLabel>
              <Select
                value={numberingSelection}
                label="Numbering Setting"
                onChange={(e) => setNumberingSelection(e.target.value)}
                fullWidth
                inputProps={{
                  "aria-label": "Select Format",
                }}
                variant="outlined"
              >
                <MenuItem value="CN">Continue Numbering</MenuItem>
                <MenuItem value="RN">Reset Numbering</MenuItem>
                <MenuItem value="NN">No Numbering</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Paper>

        {createNewSection && (
          <>
            <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
              {headerFooterSection({
                title: "Header",
                checked: headerChecked,
                onCheckChange: () => {
                  setHeaderChecked(!headerChecked);
                },
                lineCheck: isHeaderCollapsed,
                onLineChange: () => {
                  setIsHeaderCollapsed(!isHeaderCollapsed);
                },
                settings: headerSettings,
                onSettingChange: updateHeaderSetting,
              })}
            </Paper>

            <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
              {headerFooterSection({
                title: "Footer",
                checked: footerChecked,
                onCheckChange: () => {
                  setFooterChecked(!footerChecked);
                },
                lineCheck: isFooterCollapsed,
                onLineChange: () => {
                  setIsFooterCollapsed(!isFooterCollapsed);
                },
                settings: footerSettings,
                onSettingChange: updateFooterSetting,
              })}
            </Paper>

            <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                // color="#261A80"
                sx={{ marginRight: "16px" }}
              >
                {" "}
                Page Settings{" "}
              </Typography>
              <Box paddingTop={1} sx={{ width: "200px", paddingBottom: 1 }}>
                <SectionPageSetting
                  key={pageSettings[0].id}
                  title={pageSettings[0].title}
                  pageTopMargin={pageSettings[0].pageTopMargin}
                  setPageTopMargin={(value: any) =>
                    updatePageSetting(
                      0,
                      "pageTopMargin",
                      value,
                      setPageSettings
                    )
                  }
                  pageBottomMargin={pageSettings[0].pageBottomMargin}
                  setPageBottomMargin={(value: any) =>
                    updatePageSetting(
                      0,
                      "pageBottomMargin",
                      value,
                      setPageSettings
                    )
                  }
                  pageLeftMargin={pageSettings[0].pageLeftMargin}
                  setPageLeftMargin={(value: any) =>
                    updatePageSetting(
                      0,
                      "pageLeftMargin",
                      value,
                      setPageSettings
                    )
                  }
                  pageRightMargin={pageSettings[0].pageRightMargin}
                  setPageRightMargin={(value: any) =>
                    updatePageSetting(
                      0,
                      "pageRightMargin",
                      value,
                      setPageSettings
                    )
                  }
                  pageOrientation={pageSettings[0].pageOrientation}
                  setPageOrientation={(value: any) =>
                    updatePageSetting(
                      0,
                      "pageOrientation",
                      value,
                      setPageSettings
                    )
                  }
                  pageWidth={pageSettings[0].pageWidth}
                  setPageWidth={(value: any) =>
                    updatePageSetting(0, "pageWidth", value, setPageSettings)
                  }
                  pageHeight={pageSettings[0].pageHeight}
                  setPageHeight={(value: any) =>
                    updatePageSetting(0, "pageHeight", value, setPageSettings)
                  }
                  pageSize={pageSettings[0].pageSize}
                  setPageSize={(value: any) =>
                    updatePageSetting(0, "pageSize", value, setPageSettings)
                  }
                  pageColumn={pageSettings[0].pageColumn}
                  setPageColumn={(value: any) =>
                    updatePageSetting(0, "pageColumn", value, setPageSettings)
                  }
                  pageColor={pageSettings[0].pageColor}
                  setPageColor={(value: any) =>
                    updatePageSetting(0, "pageColor", value, setPageSettings)
                  }
                  headerUnderline={pageSettings[0].headerUnderline}
                  setHeaderUnderline={(value: any) =>
                    updatePageSetting(
                      0,
                      "headerUnderline",
                      value,
                      setPageSettings
                    )
                  }
                  footerUnderline={pageSettings[0].footerUnderline}
                  setFooterUnderLine={(value: any) =>
                    updatePageSetting(
                      0,
                      "footerUnderline",
                      value,
                      setPageSettings
                    )
                  }
                  raggedColumn={pageSettings[0].raggedColumn}
                  setRaggedColumn={(value: any) =>
                    updatePageSetting(0, "raggedColumn", value, setPageSettings)
                  }
                />
              </Box>
            </Paper>

            <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ marginRight: "16px" }}
                  >
                    {" "}
                    Heading Settings{" "}
                  </Typography>
                </Grid>

                <Grid item xs={2} container justifyContent="flex-end">
                  <IconButton
                    onClick={() => setIsHeadingCollapsed((prev) => !prev)}
                    aria-expanded={!isGridCollapsed}
                    aria-label="toggle grid"
                  >
                    {isHeadingCollapsed ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={isHeadingCollapsed} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    width: "56.6vw",
                  }}
                >
                  <ContentFormattingMaster
                    key={generalHeadingSettings[0].id}
                    title={generalHeadingSettings[0].title}
                    topMargin={generalHeadingSettings[0].topMargin}
                    setTopMargin={(value: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "topMargin",
                        value,
                        setGeneralHeadingSettings
                      )
                    }
                    bottomMargin={generalHeadingSettings[0].bottomMargin}
                    setBottomMargin={(value: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "bottomMargin",
                        value,
                        setGeneralHeadingSettings
                      )
                    }
                    indent={generalHeadingSettings[0].indent}
                    setIndent={(value: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "indent",
                        value,
                        setGeneralHeadingSettings
                      )
                    }
                    fontColor={generalHeadingSettings[0].fontColor}
                    setFontColor={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "fontColor",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    font={generalHeadingSettings[0].font}
                    setFont={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "font",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    fontSize={generalHeadingSettings[0].fontSize}
                    setFontSize={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "fontSize",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    fontStyle={generalHeadingSettings[0].fontStyle}
                    setFontStyle={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "fontStyle",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    alignment={generalHeadingSettings[0].alignment}
                    SetAlignment={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "alignment",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    format={
                      generalHeadingSettings[0].format === "None"
                        ? "arabic"
                        : generalHeadingSettings[0].format
                    }
                    setFormat={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "format",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    separator={generalHeadingSettings[0].separator}
                    setSeparator={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "separator",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    compound={
                      generalHeadingSettings[0].numbering
                        ? generalHeadingSettings[0].numbering
                        : generalHeadingSettings[0].compound &&
                          generalHeadingSettings[0].format !== "None"
                        ? "compound"
                        : pageSettings[0].defaultIndent &&
                          generalHeadingSettings[0].format !== "None"
                        ? "compound"
                        : generalHeadingSettings[0].format === "None"
                        ? "hidden"
                        : "single"
                    }
                    setCompound={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "numbering",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    prefix={generalHeadingSettings[0].prefix}
                    setPrefix={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "prefix",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    suffix={generalHeadingSettings[0].suffix}
                    setSuffix={(newValue: any) =>
                      updateGeneralHeadingSetting(
                        0,
                        "suffix",
                        newValue,
                        setGeneralHeadingSettings
                      )
                    }
                    object={generalHeadingSettings}
                    defaultIndent={pageSettings[0].defaultIndent}
                    setDefaultIndent={(value: any) =>
                      updatePageSetting(
                        0,
                        "defaultIndent",
                        value,
                        setPageSettings
                      )
                    }
                  />
                </Box>
                <Box paddingTop={1}>
                  <Grid container spacing={0.5}>
                    {renderContentFormatCards(
                      0,
                      headingSettings.slice(0, 3),
                      setHeadingSettings,
                      pageSettings[0].defaultIndent
                    )}
                  </Grid>
                </Box>
                {/* Collapse for paragraphs 4-6 */}
                <Box paddingTop={2}>
                  <Grid container spacing={0.5}>
                    {renderContentFormatCards(
                      3,
                      headingSettings.slice(3, 6),
                      setHeadingSettings,
                      pageSettings[0].defaultIndent
                    )}
                  </Grid>
                </Box>
              </Collapse>
            </Paper>
            <Paper elevation={1} sx={{ padding: 1, my: 1 }}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    // color="#261A80"
                    sx={{ marginRight: "16px" }}
                  >
                    {" "}
                    Paragraph Settings{" "}
                  </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <IconButton
                    onClick={() => setIsGridCollapsed((prev) => !prev)}
                    aria-expanded={!isGridCollapsed}
                    aria-label="toggle grid"
                  >
                    {isGridCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Grid>
              </Grid>

              <Collapse in={isGridCollapsed} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    width: "56.6vw",
                  }}
                >
                  <ContentFormattingMaster
                    key={generalParagraphSettings[0].id}
                    title={generalParagraphSettings[0].title}
                    topMargin={generalParagraphSettings[0].topMargin}
                    setTopMargin={(value: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "topMargin",
                        value,
                        setGeneralParagraphSettings
                      )
                    }
                    bottomMargin={generalParagraphSettings[0].bottomMargin}
                    setBottomMargin={(value: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "bottomMargin",
                        value,
                        setGeneralParagraphSettings
                      )
                    }
                    indent={generalParagraphSettings[0].indent}
                    setIndent={(value: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "indent",
                        value,
                        setGeneralParagraphSettings
                      )
                    }
                    fontColor={generalParagraphSettings[0].fontColor}
                    setFontColor={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "fontColor",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    font={generalParagraphSettings[0].font}
                    setFont={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "font",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    fontSize={generalParagraphSettings[0].fontSize}
                    setFontSize={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "fontSize",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    fontStyle={generalParagraphSettings[0].fontStyle}
                    setFontStyle={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "fontStyle",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    alignment={generalParagraphSettings[0].alignment}
                    SetAlignment={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "alignment",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    format={
                      generalParagraphSettings[0].format === "None"
                        ? "arabic"
                        : generalParagraphSettings[0].format
                    }
                    setFormat={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "format",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    separator={generalParagraphSettings[0].separator}
                    setSeparator={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "separator",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    compound={
                      generalParagraphSettings[0].numbering
                        ? generalParagraphSettings[0].numbering
                        : generalParagraphSettings[0].compound &&
                          generalParagraphSettings[0].format !== "None"
                        ? "compound"
                        : pageSettings[0].defaultIndent &&
                          generalParagraphSettings[0].format !== "None"
                        ? "compound"
                        : generalParagraphSettings[0].format === "None"
                        ? "hidden"
                        : "single"
                    }
                    setCompound={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "numbering",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    prefix={generalParagraphSettings[0].prefix}
                    setPrefix={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "prefix",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    suffix={generalParagraphSettings[0].suffix}
                    setSuffix={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "suffix",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                    object={generalParagraphSettings}
                    defaultIndent={pageSettings[0].defaultIndent}
                    setDefaultIndent={(value: any) => {
                      updatePageSetting(
                        0,
                        "defaultIndent",
                        value,
                        setPageSettings
                      );
                      setDefaultIndent(value);
                    }}
                    setContinueHeadingSetting={(newValue: any) =>
                      setContinueHeadingSetting(newValue)
                    }
                    lineSpacing={generalParagraphSettings[0].linespacing}
                    setLineSpacing={(newValue: any) =>
                      updateGeneralParagraphSetting(
                        0,
                        "linespacing",
                        newValue,
                        setGeneralParagraphSettings
                      )
                    }
                  />
                </Box>
                <Box paddingTop={1}>
                  <Grid container spacing={0.5}>
                    {renderContentFormatCards(
                      1,
                      paragraphSettings.slice(1, 4),
                      setParagraphSettings,
                      pageSettings[0].defaultIndent
                    )}
                  </Grid>
                </Box>
                {/* Collapse for paragraphs 4-6 */}
                <Box paddingTop={2}>
                  <Grid container spacing={0.5}>
                    {renderContentFormatCards(
                      4,
                      paragraphSettings.slice(4, 6),
                      setParagraphSettings,
                      pageSettings[0].defaultIndent
                    )}
                  </Grid>
                </Box>
              </Collapse>
            </Paper>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

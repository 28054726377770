interface ApiErrorState {
  currentError: string | null;
  documentLoaded: any;
}

const initialErrorState: ApiErrorState = {
  currentError: null,
  documentLoaded: null,
};

// Action Types
const SET_CURRENT_ERROR = "SET_CURRENT_ERROR";
const SET_DOCUMENT_LOADED = "SET_DOCUMENT_LOADED";

// Action Creators
export const setCurrentError = (error: string | null) => ({
  type: SET_CURRENT_ERROR,
  payload: error,
});

export const setDocumentLoaded = (isLoaded: any) => ({
  type: SET_DOCUMENT_LOADED,
  payload: isLoaded,
});

// Reducer
const apiErrorReducer = (
  state = initialErrorState,
  action: any
): ApiErrorState => {
  switch (action.type) {
    case SET_CURRENT_ERROR:
      return { ...state, currentError: action.payload };
    case SET_DOCUMENT_LOADED:
      return { ...state, documentLoaded: action.payload };
    default:
      return state;
  }
};

export default apiErrorReducer;

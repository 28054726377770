import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  ContentType,
  DocumentTemplate,
} from "../../../../../types/DocumentTemplate";

export default function DynamicTableConfig(props: {
  name: string;
  documentTemplate: DocumentTemplate;
  handleSave: any;
  handleClose: any;
}) {
  const [name, setName] = React.useState<string>(props.name);

  const [dynamicTableList, setDynamicTableList] = React.useState<string[]>([]);

  React.useEffect(() => {
    getDynamicTables(props.documentTemplate);
  }, []);

  const getDynamicTables = (documentTemplate: DocumentTemplate) => {
    const dynamicTableSet = new Set<any>(); // Use Set to store unique entries

    const tables =
      documentTemplate?.sections?.flatMap(
        (section) =>
          section.subsections?.flatMap(
            (subsection) =>
              subsection.elements?.filter(
                (element) => element.content_type === ContentType.Table
              ) ?? []
          ) ?? []
      ) ?? [];

    tables.forEach((element) => {
      const currentElement = JSON.parse(element?.content_format);
      if (currentElement.isDynamicTable) {
        dynamicTableSet.add(currentElement.dynamicVariable); // Add to Set
      }
    });

    const uniqueList = Array.from(dynamicTableSet); // Convert Set back to array

    setDynamicTableList(uniqueList); // Set the dynamic table list
  };

  const [nameError, setNameError] = React.useState("");

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const invalid_name = new RegExp("^[a-zA-Z0-9]+$");

    if (value.trim() === "") {
      setNameError("Name cannot be empty.");
    } else if (!invalid_name.test(value)) {
      setNameError("Name can only contain letters, numbers, and underscores.");
    } else {
      setNameError("");
    }

    setName(value);
  };

  const handleOptionChange = (event: any, newValue: any) => {
    setName(newValue);
    setNameError("");
  };

  return (
    <Dialog open={true} onClose={props.handleClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>Dynamic Table</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ minWidth: "20vw" }}>
            {/* <TextField
              value={name}
              label="Dynamic Table Name"
              onChange={handleNameChange}
              sx={{ marginTop: 1 , minWidth:'15vw' }}
              required
              fullWidth
              error={nameError!=""}
              helperText={
                nameError && <span style={{ color: "red" }}>{nameError}</span>
              }
            /> */}

            <Autocomplete
              id="auto"
              freeSolo
              options={dynamicTableList}
              value={name}
              onChange={handleOptionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  id="Dynamic Table Name"
                  label="Dynamic Table Name"
                  fullWidth
                  required
                  onChange={handleNameChange}
                  error={nameError != ""}
                  helperText={
                    nameError && (
                      <span style={{ color: "red" }}>{nameError}</span>
                    )
                  }
                  variant="standard"
                />
              )}
            />
          </Box>

          <Typography variant="body1" color="textPrimary"></Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => props.handleSave(name)}
          variant="contained"
          color="primary"
          disabled={name == "" || name == null || nameError != ""}
        >
          {"save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

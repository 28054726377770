/* eslint-disable no-debugger */
import * as React from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import dayjs from "dayjs";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useCallback, useRef, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useActions, {
  useAppDispatch,
  useAppSelector,
} from "../../../../app/hooks";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
} from "@mui/material";

import {
  selectWorkspaceSummaryState,
  setError,
} from "../../../editor/document/libraryContent/workspaceSummarySlice";
import LibraryContentElement from "./LibraryContentElement";
import { Search } from "../../../../types/User";
import {
  DocumentSection,
  DocumentSectionSummary,
  DocumentSubsection,
} from "../../../../types/DocumentTemplate";
import { selectEditingDocumentTemplate } from "../../editorSlice";
import { ViewPreferences } from "../../header/preferencesSlice";
import { CheckBox } from "@mui/icons-material";
import { apiGetWorkspaceSearch } from "../../../../services/workspaceAPI";
import utc from "dayjs/plugin/utc"; // Import the utc plugin
import "dayjs/locale/de";
import { selectAuth } from "../../../auth/authSlice";
import { apiGetCategory } from "../../../../services/documentTemplatesAPI";
import { SUB_CATEGORY } from "../../../../globals";
/**
 * Form for configuring library content.
 *
 * @export
 * @return {*}
 */
interface MyObject {
  [sectionId: number]: number[];
}
export const MyObjectContext = React.createContext<any>({});
// let timeoutId: NodeJS.Timeout | null = null;

export default function LibraryContentConfigForm(props: {
  closeDialogHandler?: any;
  organizationId: number;
  workspaceId: number;
  parentSubsection?: DocumentSubsection | null;
  parentSection?: DocumentSection | null;
  viewPreferences: ViewPreferences;
  isFloatingInsert?: boolean | undefined;
  sectionBelowInsert?: DocumentSection | null;
}) {
  const [myObject, setMyObject] = React.useState<any>({});
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const workspaceSummaryState = useAppSelector(selectWorkspaceSummaryState);
  const documentTemplate = useAppSelector(selectEditingDocumentTemplate);
  const actions = useActions();
  const dispatch = useAppDispatch();
  const [IsFilterOpen, SetIsFilterOpen] = useState<boolean>(false);
  const [IsOnceoffDocument, SetOnceoffDocument] = useState<boolean>(true);
  const [IsOtherWorkSpace, SetOtherWorkSpace] = useState<boolean>(false);
  const [IsArchivedShow, SetArchivedShow] = useState<boolean>(false);
  const [Purpose, SetPurpose] = useState("");
  const [Category, SetCategory] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const [selectedStartDateParse, setSelectedStartDateParse] = useState<any>("");
  const [selectedEndDateParse, setSelectedEndDateParse] = useState<any>("");

  const [sectionBelowInsert, setSectionBelowInsert] = useState<any | undefined>(
    []
  );
  const [searchString, setSearchString] = React.useState<string>("");
  const helpingVerbs = [
    "be",
    "have",
    "do",
    "can",
    "could",
    "will",
    "would",
    "shall",
    "am",
    "had",
    "has",
    "are",
    "to",
    "the",
    "a",
    "in",
    "is",
  ];

  const isMatchFound = helpingVerbs.some((verb) => verb === searchString);
  const sectionBelow = props.sectionBelowInsert!;
  const parentSubsection = props.parentSubsection!;
  const parentSection = props.parentSection!;

  const auth = useAppSelector(selectAuth);
  const [selectedDocumentSummary, setSelectedDocumentSummary] = React.useState<
    any | undefined
  >(undefined);

  const [selectedDocumentSectionSummary, setSelectedDocumentSectionSummary] =
    React.useState<DocumentSectionSummary | undefined>(undefined);
  const [subSectionElement, setsubSectionElement] = React.useState<
    any | undefined
  >([]);
  // To check is section is Selected , We will Highlight it.
  const [selectedSections, setSelectedSections] = React.useState<
    any | undefined
  >([]);
  const [categories, setCategories] = useState([]);
  const [purposes, setPurposes] = useState([]);
  // if Selected Subsection Already Exists in document,
  const [IsExistSubSection, setIsExistSubSection] = useState<boolean>(false);

  const lastValue = React.useMemo(() => {
    return sectionBelow!;
    // return props.sectionBelowInsert;
  }, []);

  React.useEffect(() => {
    async function getList() {
      const response = await apiGetCategory(
        auth.user!.default_organization_id!,
        auth.user!.default_workspace_id!
      );
      setCategories(response.category_list);
      setPurposes(response.purpose_list);
    }
    try {
      getList();
    } catch (error) {
      console.error(
        "Error While calling get Organization, Workspace, getList",
        error
      );
    }
  }, []);

  React.useEffect(() => {
    console.log("Mounting");
    actions.getWorkspaceSummary();
  }, []);

  React.useEffect(() => {
    const isSubsectionContain = documentTemplate.sections?.some((section) =>
      section.subsections?.some((subsection) =>
        Object.keys(myObject).some((key) =>
          myObject[key].includes(subsection.id)
        )
      )
    );
    if (isSubsectionContain) {
      setIsExistSubSection(true);
    } else {
      setIsExistSubSection(false);
    }
  }, [myObject]);

  const handleObjectSelection = (object: any) => {
    setMyObject(object);
  };
  const handleCancel = () => {
    props.closeDialogHandler();
  };
  const handleReset = () => {
    SetOnceoffDocument(true);
    SetOtherWorkSpace(false);
    SetArchivedShow(false);
    SetCategory("");
    SetPurpose("");
    setSelectedEndDateParse("");
    setSelectedStartDateParse("");
  };

  // Check if the selected Subsection is Already Exist in Document or not
  const handleSave = (_createCopy: boolean) => {
    if (parentSubsection && parentSection) {
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        // componentOrder: parentSubsection.component_order - 10,
        componentOrder: parentSubsection.component_order,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: subSectionElement,
        destinationSectionId: props.parentSection!.id,
        createCopy: _createCopy,
        // belowSectionID:parentSubsection.id
        // sectionBelowInsert: props.sectionBelowInsert!
      });
    }
    // Inserting at end so get the maximum component order
    else if (lastValue) {
      const sortedKeys = selectedDocumentSummary.document_section_summaries.map(
        (summary: any) => String(summary.id)
      );
      const objectKeys = Object.keys(myObject);
      const sortedObjectKeys = objectKeys.sort((a, b) => {
        return sortedKeys.indexOf(a) - sortedKeys.indexOf(b);
      });

      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        // componentOrder: lastValue.component_order - 10,
        componentOrder: lastValue.component_order,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: subSectionElement,
        SectionswithSubSections: myObject,
        sectionSequence: sortedObjectKeys,
        createCopy: _createCopy,
        // sectionBelowInsert: props.sectionBelowInsert!
      });
    } else {
      const sortedKeys = selectedDocumentSummary.document_section_summaries.map(
        (summary: any) => String(summary.id)
      );
      const objectKeys = Object.keys(myObject);
      const sortedObjectKeys = objectKeys.sort((a, b) => {
        return sortedKeys.indexOf(a) - sortedKeys.indexOf(b);
      });

      let componentOrder = 0;
      if (documentTemplate.sections!.length > 0)
        componentOrder =
          Math.max(
            ...documentTemplate.sections!.map(
              (s: DocumentSection) => s.component_order
            )
          ) + 10;
      actions.attachSection({
        sourceDocumentSectionId: selectedDocumentSectionSummary!.id,
        componentOrder: componentOrder,
        sourceDocumentId: selectedDocumentSummary.id,
        subSectionsId: subSectionElement,
        SectionswithSubSections: myObject,
        sectionSequence: sortedObjectKeys,
        createCopy: _createCopy,
      });
    }
    props.closeDialogHandler();
  };
  const handleClickDocumentTemplate = (documentTemplateSummary: any) => {
    if (documentTemplateSummary.document_section_summaries.length == 0) {
      actions.getWorkspaceSummaryDocument({
        documentID: documentTemplateSummary.id,
        workspaceID: documentTemplateSummary.workspace_id,
      });
    }
    setSelectedDocumentSummary(documentTemplateSummary);
  };
  const handleSelectDocumentSectionSummary = (sectionSummary: any) => {
    setSelectedDocumentSectionSummary(sectionSummary);
  };
  const handleSelectedSubSectionElemet = (ElementsID: any) => {
    setsubSectionElement(ElementsID);
  };

  const handleSelectedSections = (sections: any) => {
    setSelectedSections(sections);
  };
  const handleFilterOpen = () => {
    SetIsFilterOpen(true);
  };

  const consthandleAlertclose = () => {
    setSelectedDocumentSummary(null);
  };

  const handleFilterclose = () => {
    setSelectedDocumentSummary(null);
    if (selectedEndDateParse) {
      actions.getWorkspaceSummarySearch({
        search: searchString,
        once_off: IsOnceoffDocument,
        allworkspaces: IsOtherWorkSpace,
        purpose: Purpose,
        category: Category,
        allowArchive: IsArchivedShow,
        start_date: dayjs(selectedStartDate).format(
          "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
        ),
        end_date: dayjs(selectedEndDate).format("YYYY-MM-DD HH:mm:ss.SSSSSSZ"),
      });
    } else if (
      searchString ||
      IsOtherWorkSpace ||
      Purpose ||
      Category ||
      !IsOnceoffDocument ||
      IsArchivedShow
    ) {
      actions.getWorkspaceSummarySearch({
        search: searchString,
        once_off: IsOnceoffDocument,
        allworkspaces: IsOtherWorkSpace,
        purpose: Purpose,
        category: Category,
        allowArchive: IsArchivedShow,
      });
    } else {
      actions.getWorkspaceSummary();
    }

    SetIsFilterOpen(false);
  };
  const handleEndDateChange = (date: any) => {
    // const dates=date
    const dates = new Date(date);
    const currentTime = new Date();
    dates.setHours(currentTime.getHours());
    dates.setMinutes(currentTime.getMinutes());
    dates.setSeconds(currentTime.getSeconds());
    setSelectedEndDate(dates);
    setSelectedEndDateParse(date);
  };

  const handleStartDateChange = (date: any) => {
    const dates = new Date(date);
    const currentTime = new Date();
    dates.setHours(currentTime.getHours());
    dates.setMinutes(currentTime.getMinutes());
    dates.setSeconds(currentTime.getSeconds());
    setSelectedStartDate(dates);
    setSelectedStartDateParse(date);
  };

  const handleOtherWorkSpace = (e: any) => {
    SetOtherWorkSpace(e.target.checked);
  };
  const handleIsArchived = (e: any) => {
    SetArchivedShow(e.target.checked);
  };
  const handleOnceoffDocument = (e: any) => {
    SetOnceoffDocument(e.target.checked);
  };
  const disableFutureDates = (date: any) => {
    return date > new Date();
  };
  const handleOptionChangeCategory = (event: any, newValue: any) => {
    SetCategory(newValue);
  };
  const handleOptionChangePurpose = (event: any, newValue: any) => {
    SetPurpose(newValue);
  };

  const handleInputChange = (value: string) => {
    setSelectedDocumentSummary(null);

    const searchString = value;

    if (searchString !== "") {
      if (selectedStartDateParse) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
          start_date: dayjs(selectedStartDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
          end_date: dayjs(selectedEndDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
        });
      } else {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
        });
      }
    } else {
      if (selectedStartDateParse) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
          start_date: dayjs(selectedStartDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
          end_date: dayjs(selectedEndDate).format(
            "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
          ),
        });
      } else if (
        !IsOnceoffDocument ||
        IsOtherWorkSpace ||
        Purpose ||
        Category ||
        IsArchivedShow
      ) {
        actions.getWorkspaceSummarySearch({
          search: searchString,
          once_off: IsOnceoffDocument,
          allworkspaces: IsOtherWorkSpace,
          purpose: Purpose,
          category: Category,
          allowArchive: IsArchivedShow,
        });
      } else {
        actions.getWorkspaceSummary();
      }
    }
  };

  const debouncedHandleChange = useCallback(
    (value: string) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }

      timeoutId.current = setTimeout(() => {
        handleInputChange(value);
      }, 1050);
    },
    [handleInputChange]
  );

  const handleInputChanges = (value: string) => {
    setSelectedDocumentSummary(null);
    const searchString = value;
    // setSearchString(event.target.value);
    if (searchString != "") {
      if (selectedStartDateParse) {
        dispatch(
          actions.getWorkspaceSummarySearch({
            search: searchString,
            once_off: IsOnceoffDocument,
            allworkspaces: IsOtherWorkSpace,
            purpose: Purpose,
            category: Category,
            allowArchive: IsArchivedShow,
            start_date: dayjs(selectedStartDate).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
            ),
            end_date: dayjs(selectedEndDate).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
            ),
          })
        );
      } else {
        dispatch(
          actions.getWorkspaceSummarySearch({
            search: searchString,
            once_off: IsOnceoffDocument,
            allworkspaces: IsOtherWorkSpace,
            purpose: Purpose,
            category: Category,
            allowArchive: IsArchivedShow,
            // start_date:  selectedStartDate,
            // end_date: selectedEndDate,
          })
        );
      }
    } else {
      if (selectedStartDateParse) {
        dispatch(
          actions.getWorkspaceSummarySearch({
            search: searchString,
            once_off: IsOnceoffDocument,
            allworkspaces: IsOtherWorkSpace,
            purpose: Purpose,
            category: Category,
            allowArchive: IsArchivedShow,
            start_date: dayjs(selectedStartDate).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
            ),
            end_date: dayjs(selectedEndDate).format(
              "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
            ),
          })
        );
      } else if (
        !IsOnceoffDocument ||
        IsOtherWorkSpace ||
        Purpose ||
        Category ||
        IsArchivedShow
      ) {
        dispatch(
          actions.getWorkspaceSummarySearch({
            search: searchString,
            once_off: IsOnceoffDocument,
            allworkspaces: IsOtherWorkSpace,
            purpose: Purpose,
            category: Category,
            allowArchive: IsArchivedShow,
          })
        );
      } else {
        dispatch(actions.getWorkspaceSummary());
      }
    }
  };

  React.useEffect(() => {
    if (selectedDocumentSummary) {
      const summary = workspaceSummaryState.workspaceSummaries?.find(
        (summary) => summary.id === selectedDocumentSummary.id
      );
      if (summary) {
        setSelectedDocumentSummary(summary);
      }
    }
  }, [
    workspaceSummaryState.workspaceSummaries,
    selectedDocumentSummary,
    setSelectedDocumentSummary,
  ]);

  const workspaceMenuItems = workspaceSummaryState.workspaceSummaries?.map(
    (summary: any) => (
      <MenuItem
        key={summary.id}
        onClick={(e) => {
          setMyObject({});
          handleClickDocumentTemplate(summary);
        }}
        selected={summary === selectedDocumentSummary ? true : false}
      >
        <ListItemIcon>
          {summary === selectedDocumentSummary ? (
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          ) : null}
        </ListItemIcon>
        <ListItemText>{summary.name}</ListItemText>
      </MenuItem>
    )
  );
  workspaceSummaryState.searchedElement;

  const documentElementItems: any =
    selectedDocumentSummary?.document_section_summaries?.map(
      (section: DocumentSectionSummary) => {
        return (
          <MyObjectContext.Provider value={{ myObject, setMyObject }}>
            <LibraryContentElement
              key={section.id}
              selectElementHandler={handleSelectDocumentSectionSummary}
              documentTemplateSummary={selectedDocumentSummary}
              documentSectionSummary={section}
              isSelected={selectedSections.includes(section.id)}
              organizationId={props.organizationId}
              workspaceId={props.workspaceId}
              viewPreferences={props.viewPreferences}
            />
          </MyObjectContext.Provider>
        );
      }
    );

  const styles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      color: "black",
      fontSize: "18px",
      fontWeight: "bold",
    },
    spinner: {
      marginRight: "10px",
      color: "black",
    },
  };

  const renderContent = () => {
    if (
      (workspaceSummaryState.status === "idle" ||
        workspaceSummaryState.status === "failed") &&
      workspaceSummaryState.workspaceSummaries?.length === 0
    ) {
      return (
        <div style={styles.loadingContainer}>No data available to display</div>
      );
    }

    if (
      workspaceSummaryState.status === "idle" &&
      workspaceSummaryState.workspaceSummaries?.length !== 0
    ) {
      return workspaceMenuItems;
    }

    return (
      <div style={styles.loadingContainer}>
        <CircularProgress style={styles.spinner} />
        Loading...
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={(e) => {
          props.closeDialogHandler();
        }}
        fullWidth={true}
        maxWidth={"lg"}
        scroll="paper"
      >
        <DialogTitle>
          <MenuBookIcon style={{ marginRight: "20px" }} />
          Available Library Content Sections
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isMatchFound && (
          <Alert severity="error">
            Please search with Specific Keyword {searchString}
          </Alert>
        )}
        {IsExistSubSection && (
          <Alert severity="error">
            The selected subsection already exists in this Template.
          </Alert>
        )}

        <DialogContent>
          {/* workspaceSummaryState.status === "loading" ? (
          <CircularProgress></CircularProgress>
        ) : null */}
          {workspaceSummaryState.error !== undefined ? (
            <Alert
              severity="error"
              onClose={() => {
                dispatch(setError(undefined));
              }}
            >
              {workspaceSummaryState.error}
            </Alert>
          ) : null}

          {
            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography marginTop={2}>Published Documents</Typography>
                  </Grid>
                  <Grid item xs={7} textAlign="center">
                    <div style={{ verticalAlign: "top" }}>
                      <Typography marginTop={2} style={{ float: "left" }}>
                        Available Library Content
                      </Typography>
                      <Input
                        error={isMatchFound}
                        id={"__LibraryContentSection"}
                        placeholder={"Search"}
                        style={{
                          float: "right",
                          width: "320px",
                          padding: "8px 0 2px",
                        }}
                        onChange={(event) => {
                          setSearchString(event.target.value);
                          debouncedHandleChange(event.target.value);
                        }}
                      ></Input>
                      {/* {isMatchFound && (
                        <Alert severity="error">
                          Please search with Specific KeyWord {searchString}
                        </Alert>
                      )} */}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    textAlign="center"
                    style={{ padding: "28px" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleFilterOpen}
                    >
                      <FilterListIcon fontSize="small" color="primary" />
                    </Button>
                  </Grid>
                  {/* <FilterListIcon
          onclick={handleFilterOpen}
              fontSize="small"
              color="primary"
            
            /> */}
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Paper
                      sx={{
                        // mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 500,
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                      variant="outlined"
                    >
                      <List>{renderContent()}</List>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Paper
                      sx={{
                        // mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 500,
                        overflow: "hidden",
                        overflowY: "scroll",
                      }}
                      variant="outlined"
                    >
                      <List>{documentElementItems}</List>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => {
              handleSave(false);
            }}
            disabled={
              !selectedDocumentSummary ||
              !selectedDocumentSectionSummary ||
              IsExistSubSection
            }
          >
            Insert
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => {
              handleSave(true);
            }}
            disabled={
              !selectedDocumentSummary || !selectedDocumentSectionSummary
            }
          >
            Create Copy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={IsFilterOpen} onClose={handleFilterclose}>
        {/* <ExpandRoundedIcon/>   */}
        <DialogTitle style={{ paddingBottom: "0px" }}>
          Search Filter
        </DialogTitle>

        <Box sx={{ p: 2 }}>
          <Box>
            <Typography
              style={{ paddingTop: "0px" }}
              sx={{
                display: "inline",
                marginTop: "0px",
                marginBottom: "15px",
              }}
            >
              Include content from once-off Documents?
            </Typography>
            <Checkbox
              checked={IsOnceoffDocument}
              onChange={(event) => handleOnceoffDocument(event)}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                display: "inline",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              Search accross workspaces?
            </Typography>
            <Checkbox
              checked={IsOtherWorkSpace}
              onChange={(event) => handleOtherWorkSpace(event)}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                display: "inline",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              Allow archived documents?
            </Typography>
            <Checkbox
              checked={IsArchivedShow}
              onChange={(event) => handleIsArchived(event)}
            />
          </Box>
          <Autocomplete
            sx={{ marginTop: 1 }}
            id="free-solo-Category"
            freeSolo
            value={Category}
            options={categories}
            onChange={handleOptionChangeCategory}
            renderInput={(params) => (
              <TextField {...params} label="Select Category" />
            )}
          />

          <Autocomplete
            sx={{ marginTop: 1 }}
            id="free-solo-Purpose"
            freeSolo
            value={Purpose}
            options={purposes}
            onChange={handleOptionChangePurpose}
            renderInput={(params) => (
              <TextField {...params} label={`Select ${SUB_CATEGORY}`} />
            )}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <DatePicker
                sx={{ marginRight: 1 }}
                label="Published Start Date"
                value={selectedStartDateParse}
                shouldDisableDate={disableFutureDates}
                onChange={handleStartDateChange}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <DatePicker
                label="Published End Date"
                value={selectedEndDateParse}
                onChange={(e) => {
                  handleEndDateChange(e);
                }}
                shouldDisableDate={disableFutureDates}
              />
            </LocalizationProvider>
          </Box>

          {/* <DesktopDatePicker

          /> */}

          <DialogActions>
            <Button
              style={{ border: "1px solid" }}
              onClick={() => SetIsFilterOpen(false)}
            >
              Cancel
            </Button>
            <Button style={{ border: "1px solid" }} onClick={handleReset}>
              Reset
            </Button>
            <Button style={{ border: "1px solid" }} onClick={handleFilterclose}>
              Apply
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DocumentSection } from "../../../../../types/DocumentTemplate";
import useActions, { useAppSelector } from "../../../../../app/hooks";
import { selectSections } from "../../../editorSlice";
import TableOfContentsItem from "./TableOfContentsSection";
import ListSubheader from "@mui/material/ListSubheader";
import {
  selectApprovalMode,
  selectEditingDocumentTemplate,
} from "../../../editorSlice";
import { Tab, Tabs } from "@mui/material";
import { isUserFromShareLink } from "src/features/editor/document/TableElement/EditContent/helper";
import CommentsContainer from "src/features/comments/CommentsContainer";

export function TableOfContents(props: any) {
  const actions = useActions();
  const sections = useAppSelector(selectSections);
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const editingDocumentTemplate = useAppSelector(selectEditingDocumentTemplate);

  /**
   * Sort sections when they are dragged around.
   *
   * @param {*} result
   * @return {*}
   */
  function onDragEnd(result: any) {
    const updatedSectionsList = sections.filter(
      (section) => section.pending_deleted_at === null
    );

    if (!result.destination) {
      return;
    }

    actions.switchSections({
      sourceSection: updatedSectionsList[result.source.index],
      destinationSection: updatedSectionsList[result.destination.index],
    });
  }

  const handleApprovalPublishClicked = () => {
    actions.approvalImpactChanges(editingDocumentTemplate);
  };

  return (
    <>
      {/* Add code here for show Document Name */}
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          marginY: 1,
        }}
        aria-label="tabs-left-drawer"
      >
        <Tab value="one" label="Navigation" />
      </Tabs>
      <Box
        sx={{
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h4"
          color="Black"
          align="center"
          sx={{
            border: "1px solid gray",
            borderRadius: "2px",
            padding: "5px",
            maxWidth: "100%",
            fontSize: 22,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {editingDocumentTemplate.name}
        </Typography>

        {!editingDocumentTemplate.import_mode && value === "one" ? (
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "16px",
                  }}
                >
                  Navigation
                </span>
              </ListSubheader>
            }
            sx={{
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              height: "90vh",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="section_droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      paddingBottom: "100px",
                    }}
                    // Add custom styles if necessary
                  >
                    {!approvalsEnabled &&
                      sections
                        .filter(
                          (section) => section.pending_deleted_at === null
                        )
                        .map((section: DocumentSection, index: number) => (
                          <Draggable
                            key={section.id}
                            draggableId={String(section.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <TableOfContentsItem
                                  section={section}
                                  icon={<LibraryBooksIcon color="primary" />}
                                  isDragging={snapshot.isDragging}
                                  draggableProps={provided.dragHandleProps}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}

                    {approvalsEnabled &&
                      sections.map(
                        (section: DocumentSection, index: number) => (
                          <Draggable
                            key={section.id}
                            draggableId={String(section.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <TableOfContentsItem
                                  section={section}
                                  icon={
                                    section.pending_addition ? (
                                      <CreateIcon style={{ color: "green" }} />
                                    ) : section.pending_deleted_at ? (
                                      <DeleteIcon style={{ color: "red" }} />
                                    ) : section.pending_heading ? (
                                      <ChangeCircleIcon
                                        style={{ color: "orange" }}
                                      />
                                    ) : null
                                  }
                                  isDragging={snapshot.isDragging}
                                  draggableProps={provided.dragHandleProps}
                                />
                              </Box>
                            )}
                          </Draggable>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </List>
        ) : (
          isUserFromShareLink() && value === "two" && <CommentsContainer />
        )}
      </Box>
    </>
  );
}

// Memoize TableOfContentsItem to avoid unnecessary re-renders during drag
const MemoizedTableOfContentsItem = React.memo(TableOfContentsItem);

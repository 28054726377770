import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Divider,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { TabPanelProps } from "@mui/base";
import { GridSearchIcon } from "@mui/x-data-grid";
import ShareUserCard from "./shareUserCard";
import {
  AccountCircleOutlined,
  AddCircleOutline,
  AddCircleRounded,
  CloseOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { apiGetOrganizationUsers } from "src/services/organizationAPI";
import {
  apiDeleteCommenter,
  apiGetCommentingUsers,
  apiInviteUserForComments,
  apiLockUnlockCommenters,
} from "src/services/shareAndCommentsAPI";
import { DocumentTemplate } from "../../types/DocumentTemplate";
import ErrorPage from "../errorPage/ErrorPage";
import AlertDialog from "./AlertDialog";

interface ShareDocModalProps {
  open: boolean;
  onClose: () => void;
  onSend: (emails: string[]) => void;
  emails: string[];
  setEmails: any;
  currentWorkspace: any;
  documentTemplate: DocumentTemplate;
}

const ShareDocModal: React.FC<ShareDocModalProps> = ({
  open,
  onClose,
  onSend,
  emails,
  setEmails,
  currentWorkspace,
  documentTemplate,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [organizationUsersData, setorganizationUsersData] = useState<any>(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [inviteUserLoading, setInviteUserLoading] = useState(false);
  const [inviteUserError, setInviteUserError] = useState<boolean>(false);
  const [apiError, setapiError] = useState<null | any>(null);
  const [commentingUsersData, setcommentingUsersData] = useState<null | any>(
    null
  );
  const [openSnackBar, setOpenSnackBar] = useState<any>(false);
  const [limitReachedError, setlimitReachedError] = useState(false);
  const [commenterName, setcommenterName] = useState<any>(null);
  const [bulkInvites, setbulkInvites] = useState<any>([]);
  const [bulkLockUnloack, setbulkLockUnloack] = useState<any>([]);

  //Manage Users that we have invited already
  const [filterType, setFilterType] = useState("all");
  const [filteredCommentingUsers, setFilteredCommentingUsers] =
    useState<any>(null);
  const [mergedData, setmergedData] = useState<any>([]);
  const [showDeleteDialog, setshowDeleteDialog] = useState(false);
  const [selectedUser, setselectedUser] = useState<any>(null);

  const getOrganizationsUsers = async () => {
    const response = await apiGetOrganizationUsers(
      currentWorkspace.organization_id
    );
    if (response) {
      setorganizationUsersData(response.data.users);
    }
  };

  const getCommentingUsers = async () => {
    const _apiparams = {
      organization_id: currentWorkspace?.organization_id,
      workspace_id: currentWorkspace?.id,
      document_template_id: documentTemplate?.id,
    };
    const response = await apiGetCommentingUsers(
      _apiparams?.organization_id,
      _apiparams?.workspace_id,
      _apiparams?.document_template_id
    );
    if (response.data.data) {
      setcommentingUsersData(response.data);
    }
  };

  const handleBulkLockUnlock = (
    id: number,
    lock: boolean,
    initialLock: boolean
  ) => {
    setbulkLockUnloack((prev: any) => {
      const existingIndex = prev.findIndex((item: any) => item.id === id);
      if (lock === initialLock) {
        if (existingIndex !== -1) {
          return prev.filter((item: any) => item.id !== id);
        }
        return prev;
      } else {
        if (existingIndex === -1) {
          return [...prev, { id, lock }];
        } else {
          return prev.map((item: any) =>
            item.id === id ? { ...item, lock } : item
          );
        }
      }
    });
  };

  const lockUnlockCommentators = async (
    commenter_id: number,
    value: boolean
  ) => {
    const _apiparams = {
      organization_id: currentWorkspace?.organization_id,
      workspace_id: currentWorkspace?.id,
      document_template_id: documentTemplate?.id,
    };
    await apiLockUnlockCommenters(
      _apiparams?.organization_id,
      _apiparams?.workspace_id,
      _apiparams?.document_template_id,
      commenter_id,
      value
    );
  };

  // Filtered data based on search query
  const filteredData = organizationUsersData?.filter((user: any) =>
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const finalFilteredData = filteredData?.filter(
    (user: any) =>
      !commentingUsersData?.data?.some(
        (commentingUser: any) =>
          commentingUser.email.toLowerCase() === user.email.toLowerCase()
      )
  );

  useEffect(() => {
    setmergedData(() => {
      const mergedArray =
        finalFilteredData?.length > 0
          ? [...finalFilteredData, ...bulkInvites]
          : [...bulkInvites];
      const uniqueData = mergedArray.reduce(
        (acc: Map<string, any>, current) => {
          acc.set(current.email, current);
          return acc;
        },
        new Map()
      );
      return Array.from(uniqueData.values());
    });
  }, [searchQuery, commentingUsersData, bulkInvites, organizationUsersData]);

  const isExternalUser: boolean =
    finalFilteredData?.length === 0 && isEmailValid;

  const _handleInviteUsersForCommenting = async (body: any) => {
    setInviteUserLoading(true);
    const _apiparams = {
      organization_id: currentWorkspace?.organization_id,
      workspace_id: currentWorkspace?.id,
      document_template_id: documentTemplate?.id,
    };

    const response = await apiInviteUserForComments(
      _apiparams?.organization_id,
      _apiparams?.workspace_id,
      _apiparams?.document_template_id,
      body
    );

    if (response.data.success) {
      setInviteUserLoading(false);
      setOpenSnackBar(true);
      setSearchQuery("");
    } else {
      setInviteUserError(true);
      setapiError(response.data.data);
      setInviteUserLoading(false);
    }
  };

  const _handleDeleteComment = async (commenter_id: number) => {
    const _apiparams = {
      organization_id: currentWorkspace?.organization_id,
      workspace_id: currentWorkspace?.id,
      document_template_id: documentTemplate?.id,
    };
    const response = await apiDeleteCommenter(
      _apiparams?.organization_id,
      _apiparams?.workspace_id,
      _apiparams?.document_template_id,
      commenter_id
    );

    if (response.data.success) {
      getOrganizationsUsers();
      getCommentingUsers();
      setInviteUserLoading(false);
    }
  };

  useEffect(() => {
    getOrganizationsUsers();
    getCommentingUsers();
  }, [openSnackBar]);

  // Tabs
  function CustomTabPanel(props: TabPanelProps | any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (commentingUsersData) {
      setFilteredCommentingUsers(commentingUsersData?.data);
    }
  }, [commentingUsersData]);

  useEffect(() => {
    if (filterType === "all") {
      setFilteredCommentingUsers(commentingUsersData?.data);
    } else {
      const filtered = commentingUsersData?.data?.filter(
        (user: any) => user.user_type === filterType
      );
      setFilteredCommentingUsers(filtered);
    }
  }, [filterType]);

  const getExternalUsers = commentingUsersData?.data?.filter(
    (user: any) => user.user_type === "external"
  );

  const isExternalInvitesLimitReached = getExternalUsers?.length === 10;

  const handleFilterChange = (event: any) => {
    const selectedValue = event.target.value;
    setFilterType(selectedValue);
  };

  //manage snack bar

  const handleClickSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleCloseSnackBarError = (
    event?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setInviteUserError(false);
  };

  const handleCloseLimitReachError = (
    event?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setlimitReachedError(false);
  };

  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeEmail = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (value === "" || validateEmail(value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handleChangeName = (e: any) => {
    const value = e.target.value;
    setcommenterName(value);
  };

  const OnChangeBulkInvites = (user: any) => {
    const _apibody = {
      email: user.email,
      name: user.firstname
        ? user.firstname + " " + user.lastname
        : user.name
        ? user.name
        : "Nomia User",
      user_type: user.type ? "external" : "internal",
    };

    if (
      bulkInvites.some(
        (invitedUser: any) => invitedUser.email === _apibody.email
      )
    ) {
      setbulkInvites((prev: any) =>
        prev.filter((invitedUser: any) => invitedUser.email !== _apibody.email)
      );
    } else {
      setbulkInvites((prev: any) => [...prev, _apibody]);
    }
  };

  const _handleBulkInvites = () => {
    if (bulkInvites.length > 0) {
      _handleInviteUsersForCommenting({
        users: bulkInvites,
      });
    }
  };

  const _handleBulkLockUnlock = () => {
    if (bulkLockUnloack?.length > 0) {
      bulkLockUnloack?.forEach((item: any) => {
        lockUnlockCommentators(item.id, item.lock);
      });
    }
  };

  //consoles for testing
  // console.log(bulkInvites);

  return (
    <>
      <Dialog open={open} fullWidth onClose={onClose}>
        <DialogTitle>Share Document</DialogTitle>
        <Box sx={{ width: "100%", height: "60vh" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Invite People" {...a11yProps(0)} />
              <Tab label="Manage Commenting" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {value == 0 && (
            <Box sx={{ padding: 3 }}>
              <TextField
                type="email"
                fullWidth
                variant="outlined"
                placeholder="Search or add commenters"
                value={searchQuery}
                onChange={handleChangeEmail}
                error={
                  (!isEmailValid && finalFilteredData?.length === 0) ||
                  mergedData?.some(
                    (invitedUser: any) => invitedUser.email === searchQuery
                  )
                }
                helperText={
                  !isEmailValid && finalFilteredData.length === 0
                    ? "Invalid email format"
                    : mergedData?.some(
                        (invitedUser: any) => invitedUser.email === searchQuery
                      )
                    ? "Email already added"
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {searchQuery ? (
                        <div onClick={() => setSearchQuery("")}>
                          <CloseOutlined
                            sx={{ marginTop: 1, cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <GridSearchIcon />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment:
                    finalFilteredData?.length === 0 ? (
                      <InputAdornment
                        position="end"
                        sx={{ margin: 0, padding: 0 }}
                      >
                        {inviteUserLoading ? (
                          <CircularProgress size={"20px"} />
                        ) : (
                          <Button
                            sx={{ padding: 0, minWidth: 0 }}
                            disabled={
                              !isEmailValid ||
                              !searchQuery ||
                              !commenterName ||
                              mergedData?.some(
                                (invitedUser: any) =>
                                  invitedUser.email === searchQuery
                              )
                            }
                          >
                            <AddCircleRounded
                              onClick={() => {
                                if (isExternalInvitesLimitReached) {
                                  setlimitReachedError(true);
                                } else {
                                  const user = {
                                    id: null,
                                    email: searchQuery,
                                    firstname: commenterName,
                                    lastname: "",
                                    type: "external",
                                  };
                                  OnChangeBulkInvites(user);
                                  setSearchQuery("");
                                  setcommenterName("");
                                }
                              }}
                              sx={{ fontSize: "1.5rem" }}
                            />
                          </Button>
                        )}
                      </InputAdornment>
                    ) : null,
                }}
              />
              {isEmailValid && searchQuery && (
                <>
                  <Box sx={{ marginY: 2 }} />
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    placeholder="*Commenter name"
                    value={commenterName}
                    onChange={handleChangeName}
                    error={false}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
              <Box
                sx={{
                  marginY: "10px",
                  overflow: "auto",
                }}
              />
              <Box
                sx={{
                  overflow: "auto",
                  height: "45vh",
                }}
              >
                {!commentingUsersData ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {searchQuery && finalFilteredData.length == 0 && (
                      <Typography sx={{ fontSize: "12px" }}>
                        *User not found in this organization. Press add button
                        to send invite externally
                      </Typography>
                    )}
                    {mergedData?.length > 0 &&
                      commentingUsersData &&
                      mergedData?.map((user: any, index: any) => {
                        return (
                          <>
                            <ShareUserCard
                              key={user.email}
                              name={
                                user.firstname
                                  ? user.firstname + " " + user.lastname
                                  : user.name || "Nomia User"
                              }
                              email={user.email}
                              loading={inviteUserLoading}
                              onInvite={() => {
                                OnChangeBulkInvites(user);
                              }}
                              checked={bulkInvites.some(
                                (invitedUser: any) => invitedUser.id === user.id
                              )}
                            />
                          </>
                        );
                      })}
                  </>
                )}
              </Box>
            </Box>
          )}
          {value === 1 && (
            <Box sx={{ padding: 3 }}>
              <FormControl sx={{ marginBottom: 2 }} component="fieldset">
                <RadioGroup
                  row
                  value={filterType}
                  onChange={handleFilterChange}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="internal"
                    control={<Radio />}
                    label="Internal Users"
                  />
                  <FormControlLabel
                    value="external"
                    control={<Radio />}
                    label="External Users"
                  />
                </RadioGroup>
              </FormControl>
              <Box
                sx={{
                  overflow: "auto",
                  height: "45vh",
                }}
              >
                {!filteredCommentingUsers ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : filteredCommentingUsers?.length > 0 &&
                  commentingUsersData ? (
                  filteredCommentingUsers?.map((user: any, index: any) => {
                    return (
                      <ShareUserCard
                        fromInvited={true}
                        key={index}
                        name={user.fullname}
                        email={user.email}
                        status={user.lock}
                        commenter_id={user.id}
                        loading={inviteUserLoading}
                        onInvite={() => {
                          console.log("Nothing such to do");
                        }}
                        onToggleLock={handleBulkLockUnlock}
                        onDeleteCommenter={() => {
                          setselectedUser(user.id);
                          setshowDeleteDialog(true);
                        }}
                      />
                    );
                  })
                ) : (
                  <Box sx={{ padding: 2, textAlign: "center" }}>
                    <Typography variant="body1">No data found</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>

          <Button
            onClick={() => {
              onClose();
              _handleBulkInvites();
              _handleBulkLockUnlock();
            }}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="success"
              sx={{ width: "100%" }}
            >
              {`Invite sent ${searchQuery && `to ${searchQuery}`} successfully`}
            </Alert>
          </Snackbar>

          <Snackbar
            open={inviteUserError}
            autoHideDuration={6000}
            onClose={handleCloseSnackBarError}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleCloseSnackBarError}
              severity="error"
              sx={{ width: "100%" }}
            >
              {apiError}
            </Alert>
          </Snackbar>

          <Snackbar
            open={limitReachedError}
            autoHideDuration={6000}
            onClose={handleCloseLimitReachError}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleCloseLimitReachError}
              severity="error"
              sx={{ width: "100%" }}
            >
              External invites limit reached. {"\n"} Maximum limit is 10
            </Alert>
          </Snackbar>
        </DialogActions>
      </Dialog>
      <AlertDialog
        message="Are you sure you want to remove ?"
        open={showDeleteDialog}
        onCancel={() => setshowDeleteDialog(false)}
        onConfirm={() => {
          setshowDeleteDialog(false);
          _handleDeleteComment(selectedUser);
        }}
        description={
          "User will no longer be able to access comments for this document"
        }
      />
    </>
  );
};

export default ShareDocModal;

import React from "react";
import { Box, Button } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";
import "./ColorPickerStyles.css";

interface ColorPickerProps {
  showColorPicker: boolean;
  color: string;
  handleColorPickerSave: (color: string) => void;
  handleColorPickerClose: () => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  showColorPicker,
  color,
  handleColorPickerSave,
  handleColorPickerClose,
}) => {
  if (!showColorPicker) return null;

  const [selectedColor, setSelectedColor] = React.useState<string>(color);
  const [error, setError] = React.useState<string | null>(null);

  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  const handleColorChange = (color: ColorResult) => {
    if (hexRegex.test(color.hex)) {

      setSelectedColor(color.hex);
      setError(null); 
    } else {
      setError("Invalid hex color code!"); 
    }
  };

  return (
    <Box
      sx={{
        paddingLeft: 0,
        paddingTop: 2,
        zIndex: 1000,
        position: "absolute",
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "40px",
            left: "0",
            zIndex: "1",
          }}
        >
          <Box
            style={{
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              padding: "10px",
              background: "#fff",
            }}
          >
            <ChromePicker
              color={selectedColor}
              onChange={handleColorChange}
              onChangeComplete={() => console.log("change completed")}
              disableAlpha
            />

            {error && (
              <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleColorPickerClose}
                style={{ marginRight: "2px" }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => handleColorPickerSave(selectedColor)}
                disabled={!!error} 
              >
                Save
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default ColorPicker;

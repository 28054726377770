import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ChromePicker } from "react-color";
import ToggleButton from "@mui/material/ToggleButton";
import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import { Landscape, Padding, Portrait } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import React, { memo } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { ReactComponent as PortraitOrientIcon } from "../../../../Icons/Page Settings Icons/verticalorient.svg";
import { ReactComponent as LandscapeOrientIcon } from "../../../../Icons/Page Settings Icons/horizontal orient.svg";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import { ReactComponent as LeftMargin } from "../../../../Icons/Page Settings Icons/left margin.svg";
import { ReactComponent as RightMargin } from "../../../../Icons/Page Settings Icons/right margin.svg";
import { ReactComponent as BottomMargin } from "../../../../Icons/Page Settings Icons/bottom margin.svg";
import { ReactComponent as TopMargin } from "../../../../Icons/Page Settings Icons/top margin.svg";
import { ReactComponent as PageSize } from "../../../../Icons/Page Settings Icons/Page Size.svg";
import { ReactComponent as PageSizeA1 } from "../../../../Icons/Page Settings Icons/Page Size A1.svg";
import { ReactComponent as PageSizeA2 } from "../../../../Icons/Page Settings Icons/Page Size A2.svg";
import { ReactComponent as PageSizeA3 } from "../../../../Icons/Page Settings Icons/Page Size A3.svg";
import { ReactComponent as PageSizeA4 } from "../../../../Icons/Page Settings Icons/Page Size A4.svg";
import { ReactComponent as PageSizeA5 } from "../../../../Icons/Page Settings Icons/Page Size A5.svg";
import { ReactComponent as LetterPage } from "../../../../Icons/Page Settings Icons/letter.svg";
import { height } from "@mui/system";
export default memo(DocumentPageSetting);
function DocumentPageSetting(props: {
  title: string;
  pageTopMargin: any;
  setPageTopMargin: any;
  pageBottomMargin: any;
  setPageBottomMargin: any;
  pageLeftMargin: any;
  setPageLeftMargin: any;
  pageRightMargin: any;
  setPageRightMargin: any;
  pageOrientation: any;
  setPageOrientation: any;
  pageWidth: any;
  setPageWidth: any;
  pageHeight: any;
  setPageHeight: any;
  pageSize: any;
  setPageSize: any;
  pageColumn: any;
  setPageColumn: any;
  pageColor: any;
  setPageColor: any;
  headerUnderline: any;
  setHeaderUnderline: any;
  footerUnderline: any;
  setFooterUnderLine: any;
  raggedColumn: any;
  setRaggedColumn: any;
}) {
  const [showColorPicker, setShowColorPicker] = React.useState(false);

  const pageMarks = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    // { value: 4, label: "4" },
  ];

  const handleColorPickerClick = () => {
    setShowColorPicker((prevState: any) => !prevState);
  };
  const [selectedColor, setSelectedColor] = React.useState<string>(
    "#" + props.pageColor
  );

  const handleColorPicker = (color: any) => {
    setSelectedColor(color.hex);
    // props.setFontColor(color.hex);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
  };
  const handleColorPickerSave = () => {
    props.setPageColor(selectedColor.substring(1));
    setShowColorPicker(false);
  };

  return (
    <Box
      paddingTop={2}
      border={1}
      padding={2}
      paddingRight={[2, 4, 12]} // Adjust padding based on breakpoints
      marginRight={[0, 0, 70]} // Adjust margin based on breakpoints
      borderColor="lightgray"
      borderRadius={1}
      sx={{
        backgroundColor: "#faf9f5",
        width: "100%", // Set the width to 100%
        boxSizing: "border-box", // Include padding and border within the width
        "@media (max-width: 768px)": {
          padding: 1,
          paddingRight: 2,
          marginRight: 0,
        },
        "@media (max-width: 480px)": {
          padding: 1,
          paddingRight: 1,
        },
      }}
    >
      {/* page Settings */}
      <Box paddingTop={1.2}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginRight: "16px", marginBottom: "10px" }}
        >
          Margin
        </Typography>
        <Box>
          {/* <VerticalAlignTopIcon style={{ fontSize: 36 }} /> */}
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={0.8}>
              <Box alignItems="right">
                <TopMargin
                  style={{
                    marginLeft: "20px",
                    fontSize: "100px",
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                <TextField
                  type="number"
                  value={props.pageTopMargin}
                  label="Top Margin"
                  id="top-margin"
                  onChange={(e) => {
                    props.setPageTopMargin(e.target.value);
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                      marginTop: "10px",
                      paddingBottom: "8px",
                    },
                    "aria-label": "Font Size",
                    step: ".2",
                    min: "10",
                    max: "50",
                  }}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ marginTop: "16px" }} position="end">
                        mm
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.3}>
              {/* <VerticalAlignBottomIcon style={{ fontSize: 36 }} /> */}
              <Box alignItems="right">
                <BottomMargin
                  style={{
                    marginLeft: "80px",
                    fontSize: "100px",
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                <TextField
                  type="number"
                  value={props.pageBottomMargin}
                  label="Bottom Margin"
                  onChange={(e) => {
                    props.setPageBottomMargin(e.target.value);
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                      marginTop: "10px",
                      paddingBottom: "8px",
                    },
                    "aria-label": "Font Size",
                    step: ".2",
                    min: "10",
                    max: "559",
                  }}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ marginTop: "16px" }} position="end">
                        mm
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.3} justifyContent="flex-end">
              {/* <AlignHorizontalLeftIcon style={{ fontSize: 36 }} /> */}

              <LeftMargin
                style={{
                  marginLeft: "80px",
                  fontSize: "100px",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                <TextField
                  type="number"
                  value={props.pageLeftMargin}
                  label="Left Margin"
                  onChange={(e) => {
                    props.setPageLeftMargin(e.target.value);
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                      marginTop: "10px",
                      paddingBottom: "8px",
                    },
                    "aria-label": "Font Size",
                    step: ".2",
                    min: "10",
                    max: "559",
                  }}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ marginTop: "16px" }} position="end">
                        mm
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.3} justifyContent="flex-end">
              {/* <AlignHorizontalRightIcon style={{ fontSize: 36 }} /> */}
              <RightMargin
                style={{
                  marginLeft: "80px",
                  fontSize: "100px",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                <TextField
                  type="number"
                  value={props.pageRightMargin}
                  label="Right Margin"
                  onChange={(e) => {
                    props.setPageRightMargin(e.target.value);
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      padding: "16px 16px",
                      fontSize: "16px",
                      marginTop: "10px",
                      paddingBottom: "8px",
                    },
                    "aria-label": "Font Size",
                    step: ".2",
                    min: "10",
                    max: "50",
                  }}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ marginTop: "16px" }} position="end">
                        mm
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Page Size */}
      <Box paddingTop={1} paddingBottom={1}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginRight: "16px", paddingBottom: "15px" }}
        >
          Size and Colour
        </Typography>
        <Box>
          <ToggleButtonGroup
            value={props.pageSize}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newSize: string
            ) => {
              if (newSize) {
                props.setPageSize(newSize);
              }
            }}
            sx={{
              "& .MuiToggleButton-root": {
                borderRadius: "0px",
                border: "1px solid rgba(0, 0, 0, 0)", // Set border color to transparent
                margin: "0 4px", // Add margin between buttons
              },
              "& .MuiToggleButton-root.Mui-selected": {
                backgroundColor: "#261A80",
                color: "#FFFFFF",
              },
              "& .MuiToggleButton-root.custom.Mui-selected": {
                backgroundColor: "#261A80",
                color: "#FFFFFF",
              },
            }}
            aria-label="Page Size"
          >
            {/* <ToggleButton value="A1" aria-label="A1">
          <PageSizeA1 style={{ width: '100%', height: '100%' }} />
          </ToggleButton>
         
          <ToggleButton value="A2" aria-label="A2">
          <PageSizeA2/>
          </ToggleButton> */}
            <ToggleButton value="A3" aria-label="A3">
              <PageSizeA3 />
            </ToggleButton>
            <ToggleButton value="A4" aria-label="A4">
              <PageSizeA4 />
            </ToggleButton>
            <ToggleButton value="A5" aria-label="A5">
              <PageSizeA5 />
            </ToggleButton>
            <Tooltip title="Letter Page" placement="bottom">
              <ToggleButton
                value="letter"
                aria-label="letter"
                selected={props.pageSize === "letter"}
              >
                <LetterPage />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Custom Size" placement="bottom">
              <ToggleButton
                value="custom"
                aria-label="Custom"
                selected={props.pageSize === "custom"}
              >
                <PageSize />
              </ToggleButton>
            </Tooltip>

            <Tooltip title="Page Color" placement="bottom">
              <ToggleButton
                value={props.pageSize}
                aria-label="Custom"
                onClick={handleColorPickerClick}
                // onClick={()=>{setShowColorPicker(true)}}
                sx={{
                  margin: "0px",
                  // marginBottom: "35px",
                  // marginTop: "7px",
                  borderRadius: "0px",
                  //         height: "70px",
                  // width: "60px",  // Adjust this value to make the button narrower
                  backgroundColor: "#" + props.pageColor,
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#" + props.pageColor,
                  },
                }}
              >
                <FormatColorFillIcon sx={{ fontSize: "32px" }} />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>

          {showColorPicker ? (
            <div style={{ position: "relative", paddingLeft: 370 }}>
              <div
                style={{
                  position: "absolute",
                  top: "-40px",
                  left: "100",
                  zIndex: "1",
                }}
              >
                <Box
                  style={{
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                    padding: "10px",
                    background: "#fff",
                  }}
                >
                  <ChromePicker
                    color={selectedColor}
                    onChange={handleColorPicker}
                    disableAlpha
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="text"
                      fullWidth
                      onClick={handleColorPickerSave}
                      style={{ paddingRight: "15px" }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="text"
                      fullWidth
                      onClick={handleColorPickerClose}
                      style={{ paddingLeft: "15px" }}
                    >
                      Close
                    </Button>
                  </div>
                </Box>
              </div>
            </div>
          ) : null}
        </Box>

        {props.pageSize === "custom" ? (
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ marginRight: "16px", marginBottom: "16px" }}
            >
              Custom Page Size
            </Typography>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={1.5}>
                  {/* <WidthFullOutlinedIcon style={{ fontSize: 36 }} /> */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ paddingTop: "15px" }}
                  >
                    Width:
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                    <TextField
                      type="number"
                      value={props.pageWidth}
                      onChange={(e) => {
                        props.setPageWidth(e.target.value);
                      }}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          padding: "16px 16px",
                          fontSize: "16px",
                        },
                        "aria-label": "Page Width",
                        step: "10",
                        min: "10",
                        max: "550",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mm</InputAdornment>
                        ),
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1.5}>
                  {/* <LeftMargin/> */}
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ paddingTop: "15px" }}
                  >
                    Height:
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <FormControl fullWidth variant="filled" sx={{ marginTop: 0 }}>
                    <TextField
                      type="number"
                      value={props.pageHeight}
                      onChange={(e) => {
                        props.setPageHeight(e.target.value);
                      }}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          padding: "16px 16px",
                          fontSize: "16px",
                        },
                        "aria-label": "Page Height",
                        step: "10",
                        min: "10",
                        max: "550",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mm</InputAdornment>
                        ),
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : null}
      </Box>
      {/* page Orientation */}
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginRight: "16px", marginBottom: "16px" }}
        >
          Orientation
        </Typography>
        <ToggleButtonGroup
          value={props.pageOrientation}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, newMode: string) => {
            if (newMode) {
              props.setPageOrientation(newMode);
            }
          }}
          aria-label="Page Mode"
        >
          <Tooltip title="Landscape" placement="bottom">
            <ToggleButton
              value="landscape"
              aria-label="Landscape Mode"
              selected={props.pageOrientation === "landscape"}
            >
              {/* <Landscape /> */}
              <LandscapeOrientIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Portrait" placement="bottom">
            <ToggleButton
              value="portrait"
              aria-label="Portrait Mode"
              selected={props.pageOrientation === "portrait"}
            >
              {/* <Portrait /> */}
              <PortraitOrientIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>

      {/* columns  */}
      {/* <Box paddingTop={1} paddingRight={100}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginRight: "16px", marginBottom: "10px" }}
        >
          Columns
        </Typography>
        <Slider
          sx={{ marginLeft: "10px", marginTop: "10px" }}
          value={props.pageColumn}
          onChange={(e: any) => {
            props.setPageColumn(e.target.value);
          }}
          step={1}
          marks={pageMarks}
          min={1}
          max={3}
          aria-labelledby="slider-label"
        />
      </Box> */}

      {props.pageColumn > 1 && (
        <Box paddingTop={1} paddingRight={100}>
          <FormControlLabel
            label="Fill spacing?"
            control={
              <Checkbox
                checked={props.raggedColumn}
                onChange={(e) => props.setRaggedColumn(e.target.checked)}
              />
            }
            labelPlacement="start"
          />
        </Box>
      )}
    </Box>
  );
}

import React, { memo } from "react";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";

import { Autocomplete } from "@mui/material";

import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import Slider from "@mui/material/Slider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ChromePicker } from "react-color";
import FormatLineSpacingSharpIcon from "@mui/icons-material/FormatLineSpacingSharp";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import { ReactComponent as TopMargin } from "../../../../Icons/Heading Settings Icons/above margin.svg";
import { ReactComponent as BottomMargin } from "../../../../Icons/Heading Settings Icons/below margin.svg";
import { ReactComponent as LeftMargin } from "../../../../Icons/Heading Settings Icons/left margin-text.svg";
import { parse } from "path";
import { font_Families } from "../../../../globals";
import { findMatchingFont } from "../../document/HelperEditorFunctions";
import { grey } from "@mui/material/colors";

function ContentFormattingMaster(props: {
  title: string;
  topMargin: any;
  setTopMargin: any;
  bottomMargin: any;
  setBottomMargin: any;
  fontColor: any;
  setFontColor: any;
  font: any;
  setFont: any;
  fontSize: any;
  setFontSize: any;
  fontStyle: any;
  setFontStyle: any;
  alignment: any;
  SetAlignment: any;
  indent: any;
  setIndent: any;
  format: any;
  setFormat: any;
  separator: any;
  setSeparator: any;
  compound: any;
  setCompound: any;
  prefix: any;
  setPrefix: any;
  suffix: any;
  setSuffix: any;
  defaultIndent?: any;
  setDefaultIndent?: any;
  object?: any;
  continueHeadingSetting?: any;
  setContinueHeadingSetting?: any;
  lineSpacing?: any;
  setLineSpacing?: any;
}) {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const fontFamilies = font_Families;

  const marks = [
    { value: 0, label: "0mm " },
    { value: 5, label: " 5" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
    { value: 60, label: "60" },
    { value: 65, label: "65" },
    { value: 70, label: "70" },
    { value: 75, label: "75" },
    { value: 80, label: "80" },
  ];

  const isParagraph =
    props.title === "General Paragraph Settings" ||
    props.title === "Paragraph 4" ||
    props.title === "Paragraph 5" ||
    props.title === "Paragraph 6" ||
    props.title === "Title Page Paragraph" ||
    props.title === "Paragraph 2" ||
    props.title === "Paragraph 3"
      ? true
      : false;

  const [selectedColor, setSelectedColor] = React.useState<string>(
    "#" + props.fontColor
  );
  const isDisabled = false;

  const handleColorPickerClick = () => {
    setShowColorPicker((prevState: any) => !prevState);
  };
  const handlePickerClick = (setPickerClick: any) => {
    setPickerClick((prevState: any) => !prevState);
  };

  const handleheading1SeperatorChange = (e: any) => {
    const value = e.target.value;
    if (value.length <= 1) {
      props.setSeparator(value);
    }
  };

  const handleColorPicker = (color: any) => {
    setSelectedColor(color.hex);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
  };

  const handleColorPickerSave = () => {
    props.setFontColor(selectedColor.substring(1));
    setShowColorPicker(false);
  };

  const parseAlignmnet = (alignment: any) => {
    if (alignment === "raggedright") {
      return "left";
    } else if (alignment === "centering") {
      return "center";
    } else if (alignment === "raggedleft") {
      return "right";
    } else if (alignment === "justifying") {
      return "left";
    }
  };

  const formatReturn = (format: any) => {
    if (format === "None") {
      return "";
    } else if (format === "arabic") {
      return "1";
    } else if (format === "Alph") {
      return "A";
    } else if (format === "alph") {
      return "a";
    } else if (format === "roman") {
      return "i";
    } else if (format === "Roman") {
      return "I";
    } else return "";
  };

  const parsedString = (
    compound: any,
    format: string,
    prefix: string,
    suffix: string
  ) => {
    if (compound === true) {
      return prefix + formatReturn(format) + suffix;
    } else {
      return formatReturn(format);
    }
  };

  const parseFormat = () => {
    if (props.title == "Title Page Paragraph" || props.format == "None") {
      return "";
    } else {
      return parsedString(
        props.compound,
        props.format,
        props.prefix,
        props.suffix
      );
    }

    if (props.title === "Heading 1") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.title === "Heading 2") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.title === "Heading 3") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.title === "Heading 4") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.title === "Heading 5") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.title === "Heading 6") {
      if (props.compound === true) {
        return parsedString(
          props.compound,
          props.format,
          props.prefix,
          props.suffix
        );
      }
    } else if (props.compound === true) {
      return parsedString(
        props.compound,
        props.format,
        props.prefix,
        props.suffix
      );
    }

    return parsedString(
      props.compound,
      props.format,
      props.prefix,
      props.suffix
    );
  };

  const handleChange = () => {
    props.setContinueHeadingSetting(true);
  };

  return (
    <Box
      padding={1}
      paddingTop={2}
      border={1}
      borderColor="lightgray"
      borderRadius={1}
      sx={{ backgroundColor: "#faf9f5" }}
    >
      <Typography
        variant="h6"
        fontWeight={props.fontStyle.includes("bold") ? "bold" : "normal"}
        color={"#" + props.fontColor}
        fontFamily={findMatchingFont(props.font)}
        sx={{
          marginRight: "16px",
          textAlign: parseAlignmnet(props.alignment),
          fontStyle: props.fontStyle.includes("italic") ? "italic" : "normal",
          textDecoration: props.fontStyle.includes("underline")
            ? "underline"
            : "none",
        }}
      >
        {"Preview: " + parseFormat() + " " + props.title}
      </Typography>

      {props.title === "General Paragraph Settings" ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <Typography
            variant="body1"
            component="span"
            style={{ marginRight: "5px" }}
          >
            Do you want to apply heading settings?
          </Typography>
          <Button
            variant={props.continueHeadingSetting ? "contained" : "outlined"}
            onClick={handleChange}
            color="primary"
            style={{ marginLeft: "2px", borderRadius: "3px" }}
          >
            Apply
          </Button>
        </Box>
      ) : null}
      {isParagraph ? (
        <Box paddingTop={2} paddingBottom={2} paddingRight={1}>
          <Box display="flex" alignItems="center" paddingTop={2}>
            <div>
              <FormatLineSpacingSharpIcon
                style={{
                  width: "40px",
                  height: "auto",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
            </div>
            <FormControl fullWidth variant="outlined" sx={{ marginTop: 0 }}>
              <TextField
                type="number"
                value={props.lineSpacing}
                label=" Line Spacing"
                onChange={(e) => {
                  props.setLineSpacing(e.target.value);
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "16px 16px",
                    fontSize: "16px",
                  },
                  "aria-label": "LineSpacing",
                  step: ".1",
                  min: "1",
                  max: "100",
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">pt</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </Box>
      ) : null}

      {/* Heading 1 Top margin Bottom Margin */}

      <Box paddingTop={2} paddingBottom={2} paddingRight={1}>
        <Box display="flex" alignItems="center" paddingTop={2}>
          <div>
            <TopMargin
              style={{
                width: "40px",
                height: "auto",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
          </div>
          <FormControl
            variant="outlined"
            sx={{ marginTop: 0, width: "70%", marginRight: "10%" }}
          >
            <TextField
              type="number"
              value={props.topMargin}
              label="Spacing Above"
              onChange={(e) => {
                props.setTopMargin(e.target.value);
              }}
              inputProps={{
                style: {
                  textAlign: "center",
                  padding: "16px 16px",
                  fontSize: "16px",
                },
                "aria-label": "Spacing Above",
                step: "1",
                min: "1",
                max: "100",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pt</InputAdornment>
                ),
              }}
            />
          </FormControl>

          <div>
            <BottomMargin
              style={{
                width: "40px",
                height: "auto",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
          </div>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ marginTop: 0, width: "70%" }}
          >
            <TextField
              type="number"
              value={props.bottomMargin}
              label="Spacing Below"
              onChange={(e) => {
                props.setBottomMargin(e.target.value);
              }}
              inputProps={{
                style: {
                  textAlign: "center",
                  fontSize: "16px",
                },
                "aria-label": "Spacing Below",
                step: "1",
                min: "1",
                max: "100",
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pt</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Box>
      </Box>

      {/* Slider Heading 1  */}
      <Box display="flex" alignItems="center">
        <div>
          <LeftMargin
            style={{
              width: "28px",
              height: "auto",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          />
        </div>
        <Slider
          disabled={
            props.defaultIndent && props.title !== "Title Page Paragraph"
          }
          sx={{
            marginLeft: "10px",
            marginTop: "30px",
            fontSize: "10px",
            ...(props.defaultIndent && props.title !== "Title Page Paragraph"
              ? {
                  "& .MuiSlider-thumb": {
                    backgroundColor: "gray",
                    border: "2px solid gray",
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "gray",
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "#d3d3d3",
                  },
                }
              : {}),
          }}
          value={props.indent}
          onChange={(e: any) => {
            props.setIndent(e.target.value);
          }}
          step={5}
          marks={marks}
          min={0}
          max={70}
        />
      </Box>

      {props.title === "General Paragraph Settings" ||
      props.title === "General Heading Settings" ? (
        <Box>
          <FormControlLabel
            label={"Automatic Indent Alignment?"}
            sx={{ fontSize: "16px", fontWeight: "bold" }}
            control={
              <Checkbox
                checked={props.defaultIndent}
                onChange={(e) => props.setDefaultIndent(e.target.checked)}
              />
            }
            labelPlacement="start"
          />
        </Box>
      ) : null}

      {/* Fonts Font Sizes Format Heading 1*/}
      <Box paddingTop={2} paddingBottom={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              value={fontFamilies.find((option) => option.value === props.font)}
              disablePortal
              options={fontFamilies}
              onChange={(event: any, newValue: any) => {
                const selectedValue = newValue ? newValue.value : null;
                props.setFont(selectedValue);
              }}
              renderInput={(params: any) => (
                <TextField {...params} label="Font" />
              )}
              disableClearable
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontFamily: findMatchingFont(option.value) }}
                >
                  {option.label}
                </li>
              )}
            />
          </Grid>
          {props.title != "Title Page Paragraph" && (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="table-columns">Format</InputLabel>
                <Select
                  value={props.format}
                  label="Format"
                  onChange={(e) => props.setFormat(e.target.value)}
                  fullWidth
                  inputProps={{
                    "aria-label": "Select Format",
                  }}
                  variant="outlined"
                >
                  <MenuItem value="arabic">Number</MenuItem>
                  <MenuItem value="Alph">Uppercase letter</MenuItem>
                  <MenuItem value="alph">Lowercase letter</MenuItem>
                  <MenuItem value="roman">Lower case roman</MenuItem>
                  <MenuItem value="Roman">Uppercase Roman</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" sx={{ marginTop: 0 }}>
              <TextField
                type="number"
                value={props.fontSize}
                label="Font Size"
                onChange={(e) => {
                  props.setFontSize(e.target.value);
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "16px 16px",
                    fontSize: "16px",
                  },
                  "aria-label": "Font Size",
                  step: "1",
                  min: "8",
                  max: "72",
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">pt</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {/* Heading 1  Indent  and Fromatter Bold Italic  */}

      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          width: "fit-content",
          display: {
            xs: "flex",
            lg: "none",
          },

          alignItems: "center",
        }}
      >
        <Grid item xs={12} lg={6} container justifyContent="flex-start">
          <ToggleButtonGroup
            sx={{
              width: "100% !important",
            }}
            value={props.alignment}
            exclusive
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => {
              if (newAlignment) {
                props.SetAlignment(newAlignment);
              }
            }}
            aria-label="text alignment"
          >
            <ToggleButton value="raggedright" aria-label="left aligned">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton
              disabled={
                props.defaultIndent && props.title !== "Title Page Paragraph"
              }
              value="centering"
              aria-label="centered"
            >
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton
              disabled={
                props.defaultIndent && props.title !== "Title Page Paragraph"
              }
              value="raggedleft"
              aria-label="right aligned"
            >
              <FormatAlignRightIcon />
            </ToggleButton>
            <ToggleButton value="justifying" aria-label="justified">
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          container
          sx={{
            position: "relative",
            width: "fit-content",
            display: "flex",
            alignItems: "center",

            justifyContent: { xs: "flex-start", lg: "flex-end" },
          }}
        >
          <ToggleButtonGroup
            value={props.fontStyle}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newFormats: string[]
            ) => {
              props.setFontStyle(newFormats);
            }}
            aria-label="text formatting"
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton value="italic" aria-label="italic">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton value="underline" aria-label="underline">
              <FormatUnderlinedIcon />
            </ToggleButton>
            <ToggleButton
              value=""
              onClick={() => {
                handlePickerClick(setShowColorPicker);
              }}
              aria-label="color"
            >
              <FormatColorFillIcon style={{ color: props.fontColor }} />
              <ArrowDropDownIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {showColorPicker && (
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 2,
                zIndex: 1000,
                position: "absolute",
              }}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "40px",
                    left: "0",
                    zIndex: "1",
                  }}
                >
                  <Box
                    style={{
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                      padding: "10px",
                      background: "#fff",
                    }}
                  >
                    <ChromePicker
                      color={selectedColor}
                      onChange={handleColorPicker}
                      disableAlpha
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="text"
                        fullWidth
                        onClick={handleColorPickerSave}
                        style={{ paddingRight: "15px" }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="text"
                        fullWidth
                        onClick={handleColorPickerClose}
                        style={{ paddingLeft: "15px" }}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </div>
              </div>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        paddingTop={2}
        paddingBottom={1}
        sx={{
          position: "relative",
          width: "fit-content",
          // display: "flex",
          display: {
            xs: "none",
            lg: "flex",
          },
          alignItems: "center",
          paddingRight: 1,
        }}
      >
        <ToggleButtonGroup
          value={props.alignment}
          exclusive
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newAlignment: string | null
          ) => {
            if (newAlignment) {
              props.SetAlignment(newAlignment);
            }
          }}
          aria-label="text alignment"
          sx={{
            width: "50%", // Adjust the width as needed
          }}
        >
          <ToggleButton
            sx={{ width: 45, height: 50 }}
            value="raggedright"
            aria-label="left aligned"
          >
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton
            disabled={
              props.defaultIndent && props.title !== "Title Page Paragraph"
            }
            sx={{ width: 45, height: 50 }}
            value="centering"
            aria-label="centered"
          >
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton
            disabled={
              props.defaultIndent && props.title !== "Title Page Paragraph"
            }
            sx={{ width: 45, height: 50 }}
            value="raggedleft"
            aria-label="right aligned"
          >
            <FormatAlignRightIcon />
          </ToggleButton>
          <ToggleButton
            sx={{ width: 45, height: 50 }}
            value="justifying"
            aria-label="justified"
            // sx={{ marginRight: "25px" }}
          >
            <FormatAlignJustifyIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={props.fontStyle}
          onChange={(
            event: React.MouseEvent<HTMLElement>,
            newFormats: string[]
          ) => {
            props.setFontStyle(newFormats);
          }}
          aria-label="text formatting"
          sx={{
            width: "50%", // Adjust the width as needed
          }}
        >
          <ToggleButton
            value="bold"
            aria-label="bold"
            sx={{ width: 45, height: 50 }}
          >
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton
            value="italic"
            aria-label="italic"
            sx={{ width: 45, height: 50 }}
          >
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton
            value="underline"
            aria-label="underline"
            sx={{ width: 45, height: 50 }}
          >
            <FormatUnderlinedIcon />
          </ToggleButton>
          <ToggleButton
            sx={{ width: 65, height: 50 }}
            value=""
            onClick={() => {
              handlePickerClick(setShowColorPicker);
            }}
            aria-label="color"
          >
            <FormatColorFillIcon style={{ color: props.fontColor }} />
            <ArrowDropDownIcon />
          </ToggleButton>
          {showColorPicker && (
            <Box
              sx={{
                paddingLeft: 0,
                paddingTop: 2,
                zIndex: 1000,
                position: "absolute",
                // width: "400px", // Adjust the width as needed
                // height: "300px", // Adjust the height as needed
              }}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "40px",
                    left: "0",
                    zIndex: "1",
                  }}
                >
                  <Box
                    style={{
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                      padding: "10px",
                      background: "#fff",
                    }}
                  >
                    <ChromePicker
                      color={selectedColor}
                      onChange={handleColorPicker}
                      disableAlpha
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="text"
                        fullWidth
                        onClick={handleColorPickerSave}
                        style={{ paddingRight: "15px" }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="text"
                        fullWidth
                        onClick={handleColorPickerClose}
                        style={{ paddingLeft: "15px" }}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </div>
              </div>
            </Box>
          )}
        </ToggleButtonGroup>
      </Box>
      {/* Compound Moment */}
      <Box paddingTop={1}>
        {props.title != "Title Page Paragraph" ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} lg={3}>
              <Typography variant="h6" fontWeight="bold">
                Numbering
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Select
                id="heading1Compound"
                fullWidth
                variant="filled"
                value={props.compound}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  props.setCompound(selectedValue);
                }}
                disabled={props.defaultIndent}
              >
                <MenuItem value="compound">Compound</MenuItem>
                <MenuItem value="single">Single</MenuItem>
                <MenuItem value="hidden">Hidden</MenuItem>
              </Select>
            </Grid>

            {props.compound !== "single" ? (
              <>
                <Grid item xs={6} lg={3}>
                  <Typography variant="h6" fontWeight="bold">
                    Separator
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <TextField
                    margin="dense"
                    id="Seperator"
                    fullWidth
                    variant="filled"
                    disabled={isDisabled}
                    value={props.separator}
                    onChange={handleheading1SeperatorChange}
                    inputProps={{
                      maxLength: 1,
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        ) : null}

        {props.title != "Title Page Paragraph" && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} lg={3}>
              <Typography variant="h6" fontWeight="bold">
                Prefix
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                margin="dense"
                id="heading1Prefix"
                // label="Prefix"
                fullWidth
                variant="filled"
                value={props.prefix}
                // disabled={isDisabled}
                // placeholder="Provide Prefix"
                disabled={
                  props.defaultIndent && props.title !== "Title Page Paragraph"
                }
                onChange={(e) => {
                  props.setPrefix(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Typography variant="h6" fontWeight="bold">
                Suffix
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                margin="dense"
                id="heading1Suffix"
                // label="Suffix"
                fullWidth
                variant="filled"
                value={props.suffix}
                disabled={
                  props.defaultIndent && props.title !== "Title Page Paragraph"
                }
                // disabled={isDisabled}
                // placeholder="Provide Suffix"
                onChange={(e) => {
                  props.setSuffix(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}

        {props.prefix === "{" ||
        props.prefix === "}" ||
        props.prefix === "\\" ||
        props.prefix === "\\\\" ||
        props.prefix === "|" ||
        props.prefix === "*" ||
        props.prefix === "#" ||
        props.prefix === "%" ||
        props.prefix === "$" ||
        props.prefix === "-" ||
        props.prefix === "<" ||
        props.prefix === ">" ||
        props.prefix === "/" ||
        props.prefix === ":" ? (
          <Alert severity="error">This may cause issues Invalid Prefix</Alert>
        ) : null}
        {props.suffix === "{" ||
        props.suffix === "}" ||
        props.suffix === "*" ||
        props.suffix === "\\" ||
        props.suffix === "\\\\" ||
        props.suffix === "|" ||
        props.suffix === "#" ||
        props.suffix === "%" ||
        props.suffix === "$" ||
        props.suffix === "-" ||
        props.suffix === "<" ||
        props.suffix === ">" ||
        props.suffix === "/" ||
        props.suffix === ":" ? (
          <Alert severity="error">This may cause issues Invalid Suffix</Alert>
        ) : null}
      </Box>
    </Box>
  );
}
export default memo(ContentFormattingMaster);

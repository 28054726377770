import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  DocumentTemplate,
  Variable,
} from "../../../../../types/DocumentTemplate";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import EditAddVariableForm from "./EditAddVariableForm";
import useActions from "../../../../../app/hooks";
import { Padding } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  apiDeleteVariable,
  apiGetVariables,
} from "src/services/documentTemplatesAPI";
import { selectUser } from "src/features/auth/authSlice";
import { User } from "../../../../../types/User";
import {
  selectEditingDocumentTemplate,
  setVariables,
} from "src/features/editor/editorSlice";
import { getVariables } from "src/app/sagas/editor";

export default function VariablesTable(props: {
  variables: Variable[] | undefined;
}) {
  const actions = useActions();
  const dispatch = useDispatch();

  const userState = useSelector(selectUser) as User;
  const documentTemplate: DocumentTemplate | any = useSelector(
    selectEditingDocumentTemplate
  );

  const [anchorElEditDropdown, setAnchorElEditDropdown] =
    React.useState<null | HTMLElement>(null);

  const [editedVariable, setEditedVariable] = React.useState<Variable | any>(
    null as Variable | any
  );

  const variableList = (props.variables || [])
    .slice()
    .sort((a, b) => a.variable_name.localeCompare(b.variable_name));

  const [editFormOpen, setEditFormOpen] = React.useState<boolean>(false);
  const handleEditFormClickOpen = () => {
    setAnchorElEditDropdown(null);
    setEditFormOpen(true);
  };
  const handleEditFormClickClose = () => {
    setEditFormOpen(false);
  };

  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleOpenEditDropdown = (
    event: React.MouseEvent<HTMLElement>,
    variable: Variable | null
  ) => {
    setEditedVariable(variable);
    setAnchorElEditDropdown(event.currentTarget);
  };

  const handleCloseEditDropdown = () => {
    setEditedVariable(null);
    setAnchorElEditDropdown(null);
  };

  const handleAddClick = () => {
    setEditedVariable(null);
    setEditFormOpen(true);
  };

  const handleDeleteClick = async () => {
    const deleteVariableResponse = await apiDeleteVariable(
      userState.default_organization_id,
      userState.default_workspace_id,
      documentTemplate,
      editedVariable
    );

    if (deleteVariableResponse.data.success) {
      // Update the variables
      const variablesResponse = await apiGetVariables(
        userState.default_organization_id,
        userState.default_workspace_id,
        documentTemplate
      );
      const variables = variablesResponse.data.document_variables;
      // Update all the document templates.
      dispatch(setVariables(variables));
      setEditedVariable(null);
      setAnchorElEditDropdown(null);
      setDeleteOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteOpen(false);
    setEditedVariable(null);
    setAnchorElEditDropdown(null);
  };

  return (
    <>
      <Box>
        <TableContainer
          component={Paper}
          sx={{ overflow: "auto", maxHeight: "50vh", borderRadius: 0 }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFFFFF",
                  margin: 1,
                  padding: 1,
                }}
              >
                <TableCell
                  align="left"
                  sx={{
                    color: "#fff",
                    width: "50%",
                    fontSize: "1em",
                    // marginLeft: 1,
                  }}
                >
                  @Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fff",
                    padding: 0,
                    marginLeft: 2,
                    fontSize: "1em",
                    width: "40%",
                  }}
                >
                  Value
                </TableCell>
                <TableCell
                  component="th"
                  align="right"
                  sx={{
                    padding: 0,
                    // paddingTop: 1,
                    // paddingBottom: 1,
                    width: "10%",
                  }}
                >
                  <Button
                    size="small"
                    sx={{ marginRight: "4px" }}
                    startIcon={<AddIcon />}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddClick();
                      e.stopPropagation(); // Add this line to stop event propagation
                    }}
                    variant="contained"
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow key={-1000} sx={{ textAlign: "center" }}></TableRow> */}

              {
                // props.variables?
                variableList.map((variable) => (
                  <TableRow
                    key={variable.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <TableCell
                      // component="th"
                      scope="row"
                      align="left"
                      style={{
                        maxWidth: "85px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontWeight: "bold",
                        textOverflow: "ellipsis",
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginRight: 0,
                        marginTop: 0,
                        marginBottom: 0,
                        height: "auto", // Set height to 'auto'
                      }}
                      sx={{
                        paddingLeft: 2,
                      }}
                      title={`Name: ${variable.variable_name}\nValue: ${
                        variable.default_value
                      }\nType: ${
                        variable.fillable_field ? "Fillable Field" : "Variable"
                      }`}
                    >
                      {
                        <span
                          style={{
                            color: "#1236ff",
                            borderRadius: 1.5,
                            backgroundColor: "#f0fbff",
                          }}
                        >
                          {" "}
                          {variable.variable_name}
                        </span>
                      }
                    </TableCell>
                    <TableCell
                      title={variable.default_value} // Tooltip for variable_name
                      align="left"
                      style={{
                        maxWidth: "85px",
                        padding: 0,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                        margin: 0,
                      }}
                    >
                      {variable.default_value}
                    </TableCell>

                    <TableCell align="center" sx={{ padding: 0, margin: 0 }}>
                      <IconButton
                        sx={{ padding: "4px" }}
                        onClick={(e) => handleOpenEditDropdown(e, variable)}
                      >
                        <MenuIcon></MenuIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElEditDropdown}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElEditDropdown)}
          onClose={handleCloseEditDropdown}
        >
          <MenuItem onClick={handleEditFormClickOpen}>
            <ListItemIcon>
              <EditIcon></EditIcon>
            </ListItemIcon>
            <ListItemText>Edit...</ListItemText>
          </MenuItem>
          <MenuItem onClick={(e) => setDeleteOpen(true)}>
            <ListItemIcon>
              <DeleteIcon></DeleteIcon>
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        {editFormOpen ? (
          <EditAddVariableForm
            handleClose={handleEditFormClickClose}
            variable={editedVariable}
          ></EditAddVariableForm>
        ) : null}
      </Box>

      <Dialog open={deleteOpen} onClose={handleDeleteCancel}>
        <DialogTitle sx={{ fontWeight: "bold" }}>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="textPrimary">
              Do you really want to delete this{" "}
              {editedVariable?.fillable_field ? "fillable field" : "variable"}?
              This action cannot be undone.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteClick}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

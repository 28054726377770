import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import ApiIcon from "@mui/icons-material/ApiRounded";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SendIcon from "@mui/icons-material/Send";
import PreviewSharpIcon from "@mui/icons-material/VisibilitySharp";
import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { DocumentTemplate, Workspace } from "../../types/DocumentTemplate";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DocumentTemplateInfoForm from "./DocumentTemplateInfoForm";
import DocumentTemplateCloneForm from "./DocumentTemplateCloneForm";
import DocumentTemplateMoveForm from "./DocumentTemplateMoveForm";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import DocumentCreationForm from "../generation/DocumentCreationForm";
import BulkDocumentCreationForm from "../generation/BulkCreate/BulkDocumentCreationForm";
import { Snackbar, Typography } from "@mui/material";
import { selectApprovalMode } from "../editor/editorSlice";
import { useSnackbar } from "notistack";

import { enableApprovalMode } from "./../editor/editorSlice";
import {
  selectAuth,
  selectCurrentWorkspaceRole,
  selectOrganizationOwner,
  selectUserRole,
} from "../auth/authSlice";
import { USER_ROLE } from "../../types/User";
import DocumentInformationForm from "./DocumentInformation";
import { ShareOutlined } from "@mui/icons-material";
import ShareDocModal from "../share/shareDocModal";
import { setDocumentLoaded } from "../currentErrors/currentErrorSlice";

/**
 * A single library item showing a DocumentTemplate.
 *
 * @export
 * @param {{
 *   documentTemplate: DocumentTemplate;
 * }} props
 * @return {*}
 */
export default function DocumentTemplateLibraryItem(props: {
  documentTemplate: DocumentTemplate;
  workspaceRole: string;
  favourite: boolean;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();
  const template = props.documentTemplate;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userRole = useAppSelector(selectUserRole);
  const authState = useAppSelector(selectAuth);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const menuOpen = Boolean(anchorEl);
  const currentWorkspaceRole = useAppSelector(selectCurrentWorkspaceRole);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [emailShared, setemailShared] = useState(false);
  const [emails, setEmails] = useState<string[]>([""]);
  const needsApproval = props.documentTemplate.needs_approval;
  const sentForApproval = props.documentTemplate.send_for_approvals;
  const isDraft =
    props.documentTemplate.new_published_date == null &&
    props.documentTemplate.publication_no === null;
  // const isDraft = props.documentTemplate.published_date == null;

  const isDeleted = props.documentTemplate.deleted_at != null;
  const isOnceOff = props.documentTemplate.once_off;
  const badgeText = isOnceOff ? "Once-off" : "Template";
  const organizationOwner = useAppSelector(selectOrganizationOwner);
  const roleCreator = currentWorkspaceRole == "READER_CREATOR";
  const newContentAdded = props.documentTemplate.is_editing
    ? props.documentTemplate.is_editing
    : false;

  // final conditions
  const isDraft1 =
    props.documentTemplate.publication_no == null &&
    props.documentTemplate.published_date == null &&
    props.documentTemplate.new_published_date === null;

  const approvals1 =
    !props.documentTemplate.is_editing &&
    (props.documentTemplate.needs_approval ||
      props.documentTemplate.send_for_approvals);

  const badgeType =
    //  newContentAdded
    //   ? "Editing"
    // :
    approvals1 ? "Approval" : isDraft1 ? "Draft" : "Published";

  // const badgeType =
  //   //  newContentAdded
  //   //   ? "Editing"
  //   // :
  //   needsApproval || sentForApproval
  //     ? "Approval"
  //     : isDraft
  //     ? "Draft"
  //     : "Published";

  const isApproval = needsApproval || sentForApproval;
  const disabeGenerate = isApproval || newContentAdded;

  const isDocumentLocked =
    props.documentTemplate.doc_status === "locked" ? true : false;

  const handleClick = (event: any) => {
    setAnchorE2(event.currentTarget);
  };
  const lockProperties = {
    isLockedOwner: authState.user?.user_id === props.documentTemplate.locked_by,
    isDocumentLocked: isDocumentLocked,
    eligibleToUnlock:
      isDocumentLocked &&
      (userRole === USER_ROLE.ADMIN ||
        userRole === USER_ROLE.SUPER_USER ||
        authState.user?.user_id === props.documentTemplate.locked_by),
  };

  const isDraftEditing =
    props.documentTemplate.is_draft_opened || props.documentTemplate.is_editing;

  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isDraft) {
      actions.getDocumentTemplateVariables(props.documentTemplate);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [infoOpen, setInfoOpen] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [moveOpen, setMoveOpen] = React.useState(false);
  const [generateOpen, setGenerateOpen] = React.useState(false);
  const [sharePopUp, setsharePopUp] = useState(false);
  const [selectedDoc, setselectedDoc] = useState(null);
  const [bulkGenerateOpen, setBulkGenerateOpen] = React.useState(false);
  const approvalsEnabled = useAppSelector(selectApprovalMode);
  const [docInfo, setDocInfo] = React.useState(false);

  // const handleDocumentTemplateClicked = () => {
  //   if (!isDeleted) {
  //     if (
  //       needsApproval &&
  //       !props.documentTemplate.is_editing &&
  //       (currentWorkspaceRole == "PUBLISHER_CREATOR" ||
  //         currentWorkspaceRole == "PUBLISHER")
  //     ) {
  //       actions.setOpenApprovalDocumentTemplate(props.documentTemplate);
  //     } else {
  //       actions.setEditingDocumentTemplate(props.documentTemplate);
  //     }
  //   }
  // };

  const handleDocumentTemplateClicked = () => {
    if (roleCreator) return false;
    if (!isDocumentLocked) {
      if (!isDeleted) {
        if (
          needsApproval &&
          !props.documentTemplate.is_editing &&
          (currentWorkspaceRole == "PUBLISHER_CREATOR" ||
            currentWorkspaceRole == "PUBLISHER")
        ) {
          actions.setOpenApprovalDocumentTemplate(props.documentTemplate);
          console.log("NEEDS APPROVAL");
        } else {
          console.log("NOT NEEDS APPROVAL");
          actions.setEditingDocumentTemplate(props.documentTemplate);
        }
      }
    } else if (
      // (isDocumentLocked && userRole === USER_ROLE.ADMIN) ||
      // userRole === USER_ROLE.SUPER_USER
      // ||

      authState.user?.user_id === props.documentTemplate.locked_by
    ) {
      if (!isDeleted) {
        if (
          needsApproval &&
          !props.documentTemplate.is_editing &&
          (currentWorkspaceRole == "PUBLISHER_CREATOR" ||
            currentWorkspaceRole == "PUBLISHER")
        ) {
          actions.setOpenApprovalDocumentTemplate(props.documentTemplate);
        } else {
          actions.setEditingDocumentTemplate(props.documentTemplate);
        }
      }
    } else {
      enqueueSnackbar("You are not allowed to open this document.", {
        variant: "error",
      });
    }
  };

  const handleEditClicked = () => {
    if (!isDeleted) {
      setAnchorEl(null);
      actions.setEditingDocumentTemplate(props.documentTemplate);
    }
  };

  const handleFavoriteClicked = () => {
    setAnchorEl(null);
    actions.favouriteDocumentTemplate(props.documentTemplate);
  };
  const handleUnfavoriteClicked = () => {
    setAnchorEl(null);
    actions.unfavouriteDocumentTemplate(props.documentTemplate);
  };
  const handleInfoClicked = () => {
    setAnchorEl(null);
    setInfoOpen(true);
  };
  const handleDocInfoClicked = () => {
    actions.getDocumentTemplateVariables(props.documentTemplate);
    setAnchorEl(null);
    setDocInfo(true);
  };

  const handlePreviewClicked = () => {
    actions.previewDocumentTemplate(props.documentTemplate);
    setAnchorEl(null);
  };
  const handlePublishClicked = () => {
    actions.publishDocumentTemplate(props.documentTemplate);
    setAnchorEl(null);
  };

  const handleShare = (docId: number | any) => {
    console.log("Share Functionality");
    setsharePopUp(true);
    setAnchorEl(null);
    setselectedDoc(docId);
  };

  const onSendEmails = () => {
    //integration for api in order to send data and fetch
    setemailShared(true);
    const sendEmailPayload = {
      templateId: selectedDoc,
      emailIds: emails,
    };

    console.log("Send Email Payload", sendEmailPayload);
  };

  const handleLockClicked = () => {
    if (!isDocumentLocked) {
      actions.draftCollaborationDocumentTemplate({
        document_template: props.documentTemplate,
        updatedDocumentStatus: "locked",
      });
    }

    setAnchorEl(null);
  };

  const handleUnlockClicked = () => {
    if (isDocumentLocked) {
      actions.draftCollaborationDocumentTemplate({
        document_template: props.documentTemplate,
        updatedDocumentStatus: "unlocked",
      });
    }
    enqueueSnackbar(
      `${props.documentTemplate.name} unlocked successfully and is now available for other users`,
      {
        variant: "success",
      }
    );
    setAnchorEl(null);
  };
  const handleDeleteClicked = () => {
    actions.deleteDocumentTemplate(props.documentTemplate);
    setAnchorEl(null);
  };
  const handleMakeNormalClicked = () => {
    actions.makeNormalDocumentTemplate(props.documentTemplate);
    setAnchorEl(null);
  };
  const handleUndeleteClicked = () => {
    actions.undeleteDocumentTemplate(props.documentTemplate);
    setAnchorEl(null);
  };

  const handleCloneToWorkspace = () => {
    setCloneOpen(true);
    setAnchorEl(null);
  };

  const handleMoveToWorkspace = () => {
    setMoveOpen(true);
    setAnchorEl(null);
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const boxStyles = {
    transition: "background-color 0.3s, transform 0.3s",
    backgroundColor: isHovered ? "#f0f0f0" : "transparent",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    cursor: isDeleted || roleCreator ? "not-allowed" : "pointer",
    // Add other styles here
  };

  let itemContent = (
    <CircularProgress size="150px" style={{ padding: "10px" }} />
  );
  if (
    !props.documentTemplate.imagePreview ||
    props.documentTemplate.imagePreview.data == null ||
    props.documentTemplate.imagePreview.status === "failed"
  ) {
    // Show the file icon
    itemContent = (
      <InsertDriveFileIcon
        color={isDeleted ? "inherit" : "primary"}
        sx={{ fontSize: 96 }}
      />
    );
  } else if (props.documentTemplate.imagePreview?.data !== null) {
    itemContent = (
      <Paper elevation={2}>
        <img
          width="180"
          src={URL.createObjectURL(
            props.documentTemplate.imagePreview?.data as Blob
          )}
          alt="library picture"
        />
      </Paper>
    );
  }

  return (
    <Box
      style={{
        position: "relative", // Change to relative positioning
        display: "flex", // Use flexbox to control alignment
        alignItems: "flex-start", // Align items to the start of the container
        borderRadius: "3px",
        paddingBottom: "10px",
      }}
    >
      <ImageListItem
        key={template.id}
        sx={{
          justifyContent: "center",
          display: "flex",
          width: 150,
          height: 190,
        }}
      >
        <Box
          onClick={(e) => handleDocumentTemplateClicked()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={boxStyles}
        >
          <Paper
            sx={{
              // justifyContent: "center",
              display: "flex",
              width: 160,
              height: 190,
              borderRadius: "3px",
            }}
            title={
              isDeleted ? "Unarchive document to enable editing" : template.name
            }
          >
            <Box
              sx={{
                // border: "2px solid black",
                paddingLeft: "8px",
                paddingTop: "12px",
                marginRight: isDraft ? "15px" : "40px",
                whiteSpace: "nowrap", // Keep text on a single line
                overflow: "hidden", // Hide overflow content
                textOverflow: "ellipsis", // Show ellipsis (...) when text overflows
              }}
            >
              {/* Name */}
              <Typography
                variant="h5"
                sx={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflow text
                  textOverflow: "ellipsis", // Add ellipsis to overflow text
                }}
              >
                {template.name}
              </Typography>

              {/* Category */}
              <Typography
                variant="body2"
                color="black"
                sx={{
                  fontSize: "12px",
                  padding: "0px",
                  paddingLeft: "5px",

                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflow text
                  textOverflow: "ellipsis", // Add ellipsis to overflow text
                }}
              >
                {template.category}
              </Typography>

              {/* Purpose */}
              <Typography
                variant="body2"
                color="black"
                sx={{
                  fontSize: "12px",
                  padding: "0px",
                  paddingLeft: "5px",
                  whiteSpace: "nowrap", // Prevent text from wrapping
                  overflow: "hidden", // Hide overflow text
                  textOverflow: "ellipsis", // Add ellipsis to overflow text
                }}
              >
                {template.purpose}
              </Typography>
            </Box>
          </Paper>
        </Box>
        {props.workspaceRole !== undefined && props.workspaceRole != "FREE" && (
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{ width: "380px", height: "auto" }}
          >
            {!roleCreator && (
              <MenuItem
                onClick={handleEditClicked}
                disabled={
                  isDeleted ||
                  (lockProperties.isDocumentLocked &&
                    !lockProperties.isLockedOwner)
                }
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            )}
            {!props.favourite && !roleCreator && (
              <MenuItem
                onClick={handleFavoriteClicked}
                disabled={isDeleted}
                sx={{ fontSize: "14px !important" }}
              >
                <ListItemIcon>
                  <FavoriteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Favorite</ListItemText>
              </MenuItem>
            )}
            {props.favourite && !roleCreator && (
              <MenuItem onClick={handleUnfavoriteClicked} disabled={isDeleted}>
                <ListItemIcon>
                  <FavoriteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unfavorite</ListItemText>
              </MenuItem>
            )}
            {isDraft && !roleCreator ? (
              <MenuItem
                onClick={handlePublishClicked}
                disabled={
                  !["PUBLISHER", "PUBLISHER_CREATOR", "CREATOR"].includes(
                    props.workspaceRole
                  ) ||
                  isDeleted ||
                  (lockProperties.isDocumentLocked &&
                    !lockProperties.isLockedOwner)
                }
              >
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Publish</ListItemText>
              </MenuItem>
            ) : null}

            {true && (
              <MenuItem onClick={() => handleShare(template.id)}>
                <ListItemIcon>
                  <ShareOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Invite for Commenting</ListItemText>
              </MenuItem>
            )}

            {!isDocumentLocked && !roleCreator ? (
              <MenuItem onClick={handleLockClicked} disabled={isDocumentLocked}>
                <ListItemIcon>
                  <LockPersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Lock Document</ListItemText>
              </MenuItem>
            ) : null}

            {isDocumentLocked &&
            !roleCreator &&
            (userRole === USER_ROLE.ADMIN ||
              userRole === USER_ROLE.SUPER_USER ||
              currentWorkspaceRole == "PUBLISHER_CREATOR" ||
              currentWorkspaceRole == "PUBLISHER" ||
              authState.user?.user_id === props.documentTemplate.locked_by) ? (
              <MenuItem
                onClick={handleUnlockClicked}
                disabled={!isDocumentLocked}
              >
                <ListItemIcon>
                  <LockOpenIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unlock Document</ListItemText>
              </MenuItem>
            ) : null}

            {!isDraft &&
            !isDeleted &&
            props.documentTemplate.publication_no != null ? (
              <MenuItem
                onClick={() => {
                  actions.getDocumentTemplateVariables(props.documentTemplate);
                  setAnchorEl(null);
                  setGenerateOpen(true);
                }}
                title={disabeGenerate ? "Please approve this document." : ""}
              >
                <ListItemIcon>
                  <InsertDriveFileIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color:
                      //  disabeGenerate ? "grey" :

                      "black",
                  }}
                >
                  {isOnceOff
                    ? "Create PDF document"
                    : "Create PDF from template"}
                </ListItemText>
              </MenuItem>
            ) : null}

            {!isDraft &&
            !isDeleted &&
            props.documentTemplate.publication_no != null ? (
              <MenuItem
                onClick={() => {
                  // if (!disabeGenerate) {
                  actions.getDocumentTemplateVariables(props.documentTemplate);
                  setAnchorEl(null);
                  setBulkGenerateOpen(true);
                  // }
                }}
                title={disabeGenerate ? "Please approve this document." : ""}
              >
                <ListItemIcon>
                  <InsertDriveFileIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color:
                      // disabeGenerate ? "grey" :

                      "black",
                  }}
                >
                  Bulk create PDFs from template
                </ListItemText>
              </MenuItem>
            ) : null}
            {props.documentTemplate.once_off && !roleCreator && (
              <MenuItem onClick={handleMakeNormalClicked}>
                <ListItemIcon>
                  <CropRotateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Make a Normal Template</ListItemText>
              </MenuItem>
            )}
            {!isDeleted && !roleCreator && (
              <MenuItem
                disabled={
                  isApproval ||
                  (lockProperties.isDocumentLocked &&
                    !lockProperties.isLockedOwner)
                }
                onClick={handleDeleteClicked}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Archive</ListItemText>
              </MenuItem>
            )}
            {isDeleted && !roleCreator && (
              <MenuItem onClick={handleUndeleteClicked}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unarchive</ListItemText>
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handlePreviewClicked}>
              <ListItemIcon>
                <PreviewSharpIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Preview</ListItemText>
            </MenuItem>
            <a
              href={
                "http://api.nomiadocs.com/document_templates/" +
                props.documentTemplate.id +
                "/get_generate/" +
                props.documentTemplate.name
              }
              target="_blank"
            ></a>
            <MenuItem onClick={handleInfoClicked}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Document Properties</ListItemText>
            </MenuItem>

            {!isDraft && !isDeleted && organizationOwner && (
              <MenuItem
                onClick={() => {
                  handleDocInfoClicked();
                }}
              >
                <ListItemIcon>
                  <ApiIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>API Details</ListItemText>
              </MenuItem>
            )}
            {(currentWorkspaceRole == "PUBLISHER_CREATOR" ||
              currentWorkspaceRole == "PUBLISHER") && (
              <MenuItem onClick={handleMoveToWorkspace} disabled={isDeleted}>
                <ListItemIcon>
                  <CopyAllIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Move to Workspace</ListItemText>
              </MenuItem>
            )}
          </Menu>
        )}

        {props.workspaceRole == "FREE" ||
          (props.workspaceRole === undefined && (
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCloneToWorkspace} disabled={isDeleted}>
                <ListItemIcon>
                  <CopyAllIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Clone to Workspace</ListItemText>
              </MenuItem>
            </Menu>
          ))}

        {infoOpen ? (
          <DocumentTemplateInfoForm
            parentCloseHandle={() => setInfoOpen(false)}
            documentTemplate={template}
          ></DocumentTemplateInfoForm>
        ) : null}

        {docInfo ? (
          <DocumentInformationForm
            parentCloseHandle={() => setDocInfo(false)}
            documentTemplate={template}
          ></DocumentInformationForm>
        ) : null}

        {cloneOpen ? (
          <DocumentTemplateCloneForm
            parentCloseHandle={() => setCloneOpen(false)}
            workspaces={props.workspaces}
            documentTemplate={template}
            currentWorkspace={props.currentWorkspace}
          ></DocumentTemplateCloneForm>
        ) : null}

        {moveOpen ? (
          <DocumentTemplateMoveForm
            parentCloseHandle={() => setMoveOpen(false)}
            workspaces={props.workspaces}
            documentTemplate={template}
            currentWorkspace={props.currentWorkspace}
          ></DocumentTemplateMoveForm>
        ) : null}
        {generateOpen ? (
          <DocumentCreationForm
            parentCloseHandle={() => setGenerateOpen(false)}
            documentTemplate={props.documentTemplate}
          />
        ) : null}
        {sharePopUp && (
          <ShareDocModal
            emails={emails}
            setEmails={setEmails}
            open={sharePopUp}
            onClose={() => setsharePopUp(false)}
            onSend={onSendEmails}
            currentWorkspace={props.currentWorkspace}
            documentTemplate={template}
          />
        )}
        {
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={emailShared}
            onClose={() => setemailShared(false)}
            color="success"
            autoHideDuration={5000}
            message="Document shared with provided email address"
            key={"sb1"}
          />
        }
        {bulkGenerateOpen ? (
          <BulkDocumentCreationForm
            parentCloseHandle={() => setBulkGenerateOpen(false)}
            documentTemplate={props.documentTemplate}
          />
        ) : null}

        {isDocumentLocked && (
          <Box
            style={{
              position: "absolute",
              top: "4px",
              left: "128px",
              zIndex: 100,
            }}
          >
            <IconButton
              title="Locked Document"
              sx={{
                padding: "5px",
                borderRadius: "50%",
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: "lightgrey",
                background: "#FFFFFF",
              }}
              onClick={
                handleClick
                // setGenerateOpen(true);
              }
            >
              {lockProperties.isLockedOwner ? (
                <LockOpenIcon sx={{ fontSize: ".7em" }} />
              ) : (
                <LockPersonIcon sx={{ fontSize: ".7em" }} />
              )}
              {/* <LockPersonIcon sx={{ fontSize: ".7em" }} /> */}
            </IconButton>
          </Box>
        )}

        {!(isDraft || isDeleted || isDocumentLocked) && (
          <Box
            style={{
              position: "absolute",
              top: "4px",
              left: "128px",
              zIndex: 100,
            }}
          >
            <IconButton
              sx={{
                padding: "5px",
                borderRadius: "50%",
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: "lightgrey",
                background: "#FFFFFF",
              }}
              onClick={
                handleClick
                // setGenerateOpen(true);
              }
            >
              <AddIcon sx={{ fontSize: ".7em" }} />
            </IconButton>

            <Menu
              anchorEl={anchorE2}
              keepMounted
              open={Boolean(anchorE2)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  actions.getDocumentTemplateVariables(props.documentTemplate);
                  setAnchorEl(null);
                  handleClose();
                  setGenerateOpen(true);
                }}
                sx={{ fontSize: "14px !important" }}
              >
                {!isOnceOff
                  ? "Create PDF from template"
                  : "Create PDF document"}
              </MenuItem>

              {!isOnceOff && (
                <MenuItem
                  onClick={() => {
                    actions.getDocumentTemplateVariables(
                      props.documentTemplate
                    );

                    setAnchorEl(null);
                    handleClose();
                    setBulkGenerateOpen(true);
                  }}
                  sx={{ fontSize: "14px !important" }}
                >
                  Bulk create PDFs from template
                </MenuItem>
              )}
              {/* <MenuItem
              onClick={() => handleMenuItemClick("Relevance")}
              selected={librarySlice.sort === "Relevance"}
              style={{
                color: librarySlice.sort === "Relevance" ? "grey" : "inherit",
              }}
            >
              Relevance
            </MenuItem> */}
            </Menu>
          </Box>
        )}

        <Box
          style={{
            position: "absolute",
            top: "70px",
            left: "5px",
            zIndex: 100,
            padding: "2px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "18px",
              backgroundColor: isOnceOff ? "#FF0062" : "#4C33FF",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "white",
              // fontWeight: 'bold',
              // textTransform: 'uppercase',
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              fontSize: "13px", // Adjust the font size as needed
              padding: "2px",
              paddingTop: "4px",
              paddingLeft: "2px",
              fontFamily: "MarkPro",
            }}
          >
            {badgeText}
          </Box>
        </Box>

        <Box
          style={{
            position: "absolute",
            top: "100px",
            left: "5px",
            zIndex: 100,
            padding: "2px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "18px",
              // backgroundColor: isDraft
              //   ? "#ed7d31"
              //   : (needsApproval || sentForApproval)
              //   ? "#00C482"
              //   : "#548235",
              backgroundColor:
                //  newContentAdded
                //   ? "#ee9e25"
                //   :
                badgeType === "Published"
                  ? "#548235"
                  : needsApproval || sentForApproval
                  ? "#00C482"
                  : isDraft
                  ? "#ed7d31"
                  : "#548235",

              borderRadius: "3px",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              color: "white",
              // fontWeight: 'bold',
              // textTransform: 'uppercase',
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              fontSize: "13px", // Adjust the font size as needed
              padding: "2px",
              paddingLeft: "2px",
              paddingTop: "4px",
              fontFamily: "MarkPro",
            }}
          >
            {badgeType}
          </Box>
        </Box>

        {isDeleted || isDraftEditing ? (
          <Box
            style={{
              position: "absolute",
              top: "128px",
              left: "5px",
              zIndex: 100,
              padding: "2px",
            }}
          >
            <Box
              sx={{
                width: "80px",
                height: "18px",
                backgroundColor: isDeleted
                  ? // || isDocumentLocked

                    "#ff0000"
                  : "#ee9e25",
                borderRadius: "3px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                color: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "13px", // Adjust the font size as needed
                padding: "2px",
                paddingLeft: "2px",
                paddingTop: "4px",
                fontFamily: "MarkPro",
              }}
            >
              {
                // isDocumentLocked
                //   ? "Locked"
                //   :

                isDeleted ? "Archived" : isDraftEditing ? "Editing" : ""
              }
            </Box>
          </Box>
        ) : null}
        <Box
          style={{
            position: "absolute",
            top: "150px",
            // left: '5px', // Moved to the left corner
            zIndex: 400,
            margin: 0,
            padding: 0,
            width: "100%",
            // border:"2px solid black"
          }}
        >
          <IconButton
            sx={{
              margin: 0, // Pushes the button to the left corner
              padding: 0,
            }}
            aria-label={`info about ${template.purpose}`}
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>

          <IconButton
            key="heart"
            disableRipple
            sx={{
              color: props.favourite ? "blue" : "black",
              marginLeft: "85px",
            }}
            onClick={() => {
              if (props.favourite) {
                handleUnfavoriteClicked();
              } else {
                handleFavoriteClicked();
              }
            }}
          >
            {props.favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </Box>
      </ImageListItem>
    </Box>
  );
}

{
  /* <Badge
          badgeContent={isDeleted ? null : isDraft ? "DRAFT" : ( needsApproval ? "PUBLISHED+" : "PUBLISHED")}
          color={isDeleted ? undefined : isDraft ? "warning" : "success"}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
         
        >
          <IconButton
            data-index={template.id}
            key={template.id}
            onClick={(e) => handleDocumentTemplateClicked()}
            
          >
            
            {itemContent}
          </IconButton>
        </Badge> */
}

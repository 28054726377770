import {
  getUpdatedSubsectionByID,
  isEditorDisabled,
} from "../../features/editor/document/HelperEditorFunctions";
import { attachedSubsectionElement } from "../../features/editor/document/utils";
import { DocumentMaster } from "../../types/DocumentMaster";
import {
  ContentType,
  DocumentElement,
  DocumentSection,
  DocumentSectionCreateRequest,
  DocumentSubsection,
  DocumentTemplate,
} from "../../types/DocumentTemplate";

/**
 *
 * Reset the component ordering below the provided item.
 * Sets to the order of the list, and returns a copy of modified items.
 *
 * E.g.:
 * - Set the item at the index to itemComponentOrder.
 * - Set all children below to be monotonically increasing component order after that.
 * - Return shallow copies of modified items (excluding the original item, insert that yourself)
 */

export function fixSectionFormatMaster(
  _documentTemplate: DocumentTemplate,
  sectionFormattingMaster: any,
  documentTemplateMaster: any
) {
  const myObject: { [key: string]: string } = {};
  const documentTemplate = _documentTemplate;

  if (
    !documentTemplate ||
    !sectionFormattingMaster ||
    !documentTemplateMaster
  ) {
    console.error("Error: One or more parameters are null or empty.");
    return myObject; // Return empty object if any parameter is null or empty
  }
  const filteredSections = documentTemplate.sections?.filter(
    (_) => !isEditorDisabled(_.heading) && _.pending_deleted_at === null
  );
  // Iterate through sections only if documentTemplate.sections is defined
  filteredSections?.map((section: DocumentSection, index: number) => {
    const sectionId = section.id;
    const sectionFormat = sectionFormattingMaster[sectionId];
    // Check if sectionFormat exists and is non-empty
    if (sectionFormat && !isEditorDisabled(section.heading)) {
      const sectionFormatObj = JSON.parse(sectionFormat);
      // Check for conditions if it follows Document master or if it first section and previous section is on
      if (
        sectionFormatObj.followDTM ||
        (index == 0 && sectionFormatObj.previousSettings)
      ) {
        // Handle followDTM case if needed
        myObject[sectionId] = documentTemplateMaster;
      } else if (
        sectionFormatObj.previousSettings &&
        index > 0 &&
        documentTemplate.sections
      ) {
        const prevSectionId = filteredSections[index - 1]?.id; // Get previous section id if exists
        const prevFormat = myObject[prevSectionId];
        myObject[sectionId] = prevFormat;
      } else {
        myObject[sectionId] = sectionFormatObj; // Store as stringified JSON
      }
    }
  });

  return myObject;
}

export function fixComponentOrderFromUpdatedElement(
  items:
    | DocumentSection[]
    | DocumentSectionCreateRequest[]
    | DocumentSubsection[],
  itemIndex: number,
  itemComponentOrder: number
) {
  let currentOrder = itemComponentOrder;
  const modifiedList = [];

  //Always write the base item.
  const baseItem = { ...items[itemIndex] };
  baseItem.component_order = currentOrder;
  modifiedList.push(baseItem);
  //Confirm that all items below are uniform.
  for (let i = itemIndex + 1; i < items.length; i++) {
    const item = { ...items[i] };
    if (item.component_order <= currentOrder) {
      item.component_order = currentOrder + 10;
      modifiedList.push(item);
    }
    currentOrder = item.component_order;
  }
  return modifiedList;
}

export function fixHeadingLevelsOfCompleteSection(
  documentTemplate: DocumentTemplate,
  documentSection: DocumentSection
) {
  const headinglevelsToBeUpdated: any[] = [];
  try {
    // find the selected SubSection
    documentTemplate.sections?.map((currentSection, index) => {
      if (currentSection.id === documentSection.id) {
        // Check if Subsections Exist
        if (currentSection.subsections!.length > 0) {
          /// loop through the subsection to find Elements
          currentSection.subsections?.map((subSection, subSectionIndex) => {
            /// implementing the logic : if heading found ,  find the child of that perticualr sub heading and update the child if it is Text Element and Level greater than 1
            if (subSection!.elements![0].content_type === "HEADING") {
              const aboveheadingLevel = subSection!.elements![0].heading_level;
              /// find the all the child text Element
              const childTextElements = findTextElementBelowSubHeading(
                documentTemplate,
                currentSection,
                subSection
              );
              /// traverse through the childs
              console.log("childTextElements ", childTextElements);
              //    {
              //     "ParentSubHeading":
              //     "type": "TEXT",
              //     "SubsectionID": 27706,
              //     "heading_Level": 3,
              //     "ElementID": 31032,
              //     "subSection"
              // }
              for (const currentTextElement of childTextElements) {
                headinglevelsToBeUpdated.push({});
              }
            }
          });
        }
      }
    });
  } catch (error) {
    console.error("Unable to Fix Elements Heading Level of Section: ", error);
  }
}

/**
 * Get all the subsections below this subheading, until either
 * the next subheading or the next section is hit.
 *
 * Returns the subsections (including the initial subheading) that
 * are part of the block.
 * @export
 * @param {DocumentSection} section
 * @param {DocumentSubsection} subHeadingSubsection
 */
export function getSubheadingBlock(
  subSections: DocumentSubsection[],
  subHeadingStartIndex: number
): DocumentSubsection[] {
  // Work through the rest of the subsections until we hit a heading.
  const block = [subSections[subHeadingStartIndex]] as DocumentSubsection[];
  for (let i = subHeadingStartIndex + 1; i < subSections.length; i++) {
    const subsection = subSections[i];
    if (
      (subsection.elements &&
        subsection.elements![0].content_type === ContentType.Heading) ||
      subsection.elements![0].content_type === ContentType.Text ||
      subsection.elements![0].content_type === ContentType.Image ||
      subsection.elements![0].content_type === ContentType.Spacer ||
      subsection.elements![0].content_type === ContentType.PageBreak ||
      subsection.elements![0].content_type === ContentType.Table ||
      subsection.elements![0].content_type === ContentType.columnStart ||
      subsection.elements![0].content_type === ContentType.columnEnd
    )
      break;
    // It isn't a heading - append it to the block.
    block.push(subsection);
  }

  return block;
}

export function getSubheadingBlockSubSection(
  subSections: DocumentSubsection[],
  subHeadingStartIndex: number
): DocumentSubsection[] {
  // Work through the rest of the subsections until we hit a heading.
  const block = [subSections[subHeadingStartIndex]] as DocumentSubsection[];
  for (let i = subHeadingStartIndex + 1; i < subSections.length; i++) {
    const subsection = subSections[i];
    if (
      subsection.elements &&
      subsection.elements![0].content_type === ContentType.Heading
    )
      break;
    // It isn't a heading - append it to the block.
    block.push(subsection);
  }

  return block;
}

// To find the child of that perticular Element

export function adjustChildLevels(
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subSection: DocumentSubsection,
  updatingElement: DocumentElement
) {
  const block = [] as DocumentSubsection[];
  let shouldPush = true; // Flag to determine whether to push the subsections or not

  documentTemplate.sections?.some((sectionMap) => {
    if (sectionMap.id === section.id) {
      return sectionMap.subsections?.some((subSectionMap) => {
        if (subSectionMap.id === subSection.id) {
          if (
            subSectionMap.elements &&
            subSectionMap.elements[0] &&
            subSectionMap.elements.length > 0 &&
            subSectionMap.elements[0].heading_level <
              updatingElement.heading_level
          ) {
            shouldPush = false; // Stop pushing the subsections
          }
          return true; // Exit the current some loop
        }

        if (shouldPush) {
          block.push(subSectionMap);
        }

        return false; // Continue iterating through the subsections
      });
    }

    return false; // Continue iterating through the sections
  });

  return block;
}

export const findParentElementToFindPosition = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection | null,
  subsection: DocumentSubsection | null
): {
  subsection: DocumentSubsection | null;
  aboveSameLevel: null | DocumentSubsection;
  parentHeadingElement: null | DocumentSubsection;
} => {
  let parentSubHeading: { subsection: DocumentSubsection | null } = {
    subsection: null,
  };
  let aboveSamelevel: { aboveSameLevel: DocumentSubsection | null } = {
    aboveSameLevel: null,
  };
  let parentHeadingElement: {
    parentHeadingElement: DocumentSubsection | null;
  } = { parentHeadingElement: null };
  let parentLevelFound = false;
  const elementLevel = subsection?.elements?.[0]?.heading_level ?? 2;

  try {
    if (section === null || subsection === null) {
      return {
        subsection: null,
        aboveSameLevel: null,
        parentHeadingElement: null,
      };
    }

    documentTemplate.sections?.forEach((docSection) => {
      if (docSection.id === section.id && !parentLevelFound) {
        docSection.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsection.id &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            (subSection.elements[0].content_type === "HEADING" ||
              subSection.elements[0].content_type === "TEXT") &&
            !parentLevelFound
          ) {
            /// added this to  only return the same heading level of parent subheading element
            if (subSection.elements[0].content_type === "HEADING") {
              parentHeadingElement = { parentHeadingElement: subSection };
              aboveSamelevel = { aboveSameLevel: null };
            }
            if (
              subSection.elements[0].content_type === "TEXT" &&
              subSection.elements[0].heading_level === elementLevel &&
              JSON.parse(subSection.elements[0].content_format)
                .isNumberDisplay == true
            ) {
              aboveSamelevel = { aboveSameLevel: subSection };
            }
            if (
              !(
                subSection.elements[0].content_type === "TEXT" &&
                JSON.parse(subSection.elements[0].content_format)
                  .isNumberDisplay == false
              )
            )
              parentSubHeading = { subsection: subSection };
          } else if (subSection.id === subsection.id && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    return {
      subsection: null,
      aboveSameLevel: null,
      parentHeadingElement: null,
    };
  }

  return {
    subsection: parentSubHeading.subsection,
    aboveSameLevel: aboveSamelevel.aboveSameLevel,
    parentHeadingElement: parentHeadingElement.parentHeadingElement,
  };
};

export const findParentOfTextElement = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  let parentSubHeading = {};
  let parentLevelFound = false;
  const subsectionID = subsection ? subsection.id : null;
  const sectionID = section ? section.id : null;

  try {
    if (section === null || subsection === null) {
      return 1;
    }
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            (subSection.elements[0].content_type === "HEADING" ||
              subSection.elements[0].content_type === "TEXT") &&
            subSection.elements[0].heading_level !== 1 &&
            !parentLevelFound
          ) {
            parentSubHeading = subSection;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    return 1;
  }

  return parentSubHeading;
};

export const findParentSubHeadingID = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsectionAbove: DocumentSubsection
) => {
  let parentSubHeadingID = 1;
  let parentLevelFound = false;
  const subsectionID = subsectionAbove.id;
  const sectionID = section.id;
  try {
    if (
      subsectionAbove.elements &&
      subsectionAbove.elements[0].content_type === "HEADING"
    ) {
      parentSubHeadingID = subsectionAbove.id;
    } else {
      documentTemplate.sections?.forEach((section) => {
        if (section.id === sectionID && !parentLevelFound) {
          section.subsections?.forEach((subSection) => {
            if (
              subSection.id !== subsectionID &&
              subSection.elements &&
              subSection.elements[0] !== undefined &&
              subSection.elements[0].content_type === "HEADING" &&
              !parentLevelFound
            ) {
              parentSubHeadingID = subSection.id;
            } else if (
              subSection.id === subsectionID &&
              !parentLevelFound &&
              subSection.component_order == subsectionAbove.component_order
            ) {
              parentLevelFound = true;
            }
          });
        }
      });
    }
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);
    // You can throw the error or return a default value if needed
    throw error;
    // parentLevel = 1; // Example default value
  }

  return parentSubHeadingID;
};

export const findParentSubHeadingLevel = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  let parentSubHeadingLevel = 1;
  let parentLevelFound = false;
  const subsectionID = subsection ? subsection.id : null;
  const sectionID = section ? section.id : null;

  try {
    if (section === null || subsection === null) {
      return 1;
    }
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            subSection.elements[0].content_type === "HEADING" &&
            subSection.pending_deleted_at === null &&
            !parentLevelFound
          ) {
            parentSubHeadingLevel =
              attachedSubsectionElement(subSection).heading_level;
          } else if (
            subSection.id === subsectionID &&
            !parentLevelFound &&
            subsection.component_order == subSection.component_order
          ) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    return 1;
  }
  return parentSubHeadingLevel;
};

export const findParentTextElementLevel = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  let parentSubHeadingLevel = 1;
  let parentLevelFound = false;
  let content_type = "HEADING";
  const subsectionID = subsection ? subsection.id : null;
  const sectionID = section ? section.id : null;

  try {
    if (section === null || subsection === null) {
      return parentSubHeadingLevel;
    }
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          const attchedElement = attachedSubsectionElement(subSection);
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.pending_deleted_at === null &&
            subSection.elements[0] !== undefined &&
            (subSection.elements[0].content_type === "HEADING" ||
              subSection.elements[0].content_type === "TEXT") &&
            attchedElement.heading_level !== 1 &&
            !parentLevelFound
          ) {
            parentSubHeadingLevel = attchedElement.heading_level;
            content_type = subSection.elements[0].content_type;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    return 1;
  }
  return parentSubHeadingLevel;
};

export const findTextElementBelowSubHeading = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  const childSubsections: any[] = [];
  let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;

  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID) {
        if (currentSection.subsections) {
          currentSection.subsections.forEach(
            (currentSubsection, subSectionIndex) => {
              if (currentSubsection.id === subsectionID) {
                // get elements of the current subsection
                const elements = currentSubsection.elements;
                // if elements exist
                if (elements && elements.length > 0) {
                  // find the heading level of the current subsection
                  const subsectionLevel = elements[0].heading_level;
                  // if the subsection is not empty
                  if (currentSection.subsections !== undefined) {
                    // now loop below the found element from the section
                    for (
                      let i = subSectionIndex + 1;
                      i < currentSection.subsections.length;
                      i++
                    ) {
                      // select the next subsection
                      const currentChildSubsection =
                        currentSection.subsections[i];
                      // select the elements from the subsection
                      const childElements = currentChildSubsection.elements;
                      // check if elements exist
                      if (childElements && childElements.length > 0) {
                        // select the current element's level
                        const childSubsectionLevel =
                          childElements[0].heading_level;
                        if (
                          childElements[0].content_type === "TEXT" &&
                          childElements[0].heading_level !== 1
                        ) {
                          childSubsections.push({
                            ParentSubHeading: subsection,
                            type: childElements[0].content_type,
                            SubsectionID: currentChildSubsection.id,
                            heading_Level: childSubsectionLevel,
                            ElementID: childElements[0].id,
                            subSection: currentChildSubsection,
                          });
                        } else if (
                          childElements[0].content_type === "HEADING"
                        ) {
                          // If a heading is encountered, stop looking for text elements below
                          return childSubsections;
                        }
                      }
                    }
                  }
                }
              }
            }
          );
        }
        parentLevelFound = true;
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    // Return an empty array or handle the error based on your requirements
    return childSubsections;
  }
  return childSubsections;
};

export const findParentTextElementLevelDetails = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  let parentSubHeadingLevel = 1;
  let parentLevelFound = false;
  let content_type = "HEADING";
  const subsectionID = subsection ? subsection.id : null;
  const sectionID = section ? section.id : null;
  try {
    if (section === null || subsection === null) {
      return {
        element_type: content_type,
        parentSubHeadingLevel: parentSubHeadingLevel,
      };
    }
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            (subSection.elements[0].content_type === "HEADING" ||
              subSection.elements[0].content_type === "TEXT") &&
            subSection.elements[0].heading_level !== 1 &&
            subSection.pending_deleted_at === null &&
            !parentLevelFound
          ) {
            parentSubHeadingLevel =
              subSection.elements[subSection.elements.length - 1].heading_level;
            content_type = subSection.elements[0].content_type;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    return {
      element_type: content_type,
      parentSubHeadingLevel: parentSubHeadingLevel,
    };
  }

  return {
    element_type: content_type,
    parentSubHeadingLevel: parentSubHeadingLevel,
  };
};

export const findChildSubSectionsOfSubHeadingH6 = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection
) => {
  const childSubsections: any = [];
  let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;

  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID && !parentLevelFound) {
        if (currentSection.subsections) {
          currentSection.subsections.forEach(
            (currentSubsection, subSectionIndex) => {
              if (currentSubsection.id === subsectionID) {
                // get element of props subsection
                const elements = currentSubsection.elements;
                // if element exist
                if (elements && elements.length > 0) {
                  // find props heading level
                  const subsectionLevel = elements[0].heading_level;
                  // if subsection is not empty
                  if (currentSection.subsections != undefined) {
                    // now loop below the finded props element from the section
                    for (
                      let i = subSectionIndex + 1;
                      i < currentSection.subsections.length;
                      i++
                    ) {
                      // select the next subsection
                      const currentChildSubsection =
                        currentSection.subsections[i];
                      // select the element from  subsection
                      const childElements = currentChildSubsection.elements;
                      // check if element exist
                      if (childElements && childElements.length > 0) {
                        // select the current element level
                        const childSubsectionLevel =
                          childElements[0].heading_level;

                        if (
                          subsectionLevel === childSubsectionLevel ||
                          (childElements[0].content_type === "TEXT" &&
                            childElements[0].heading_level === 1)
                        ) {
                          if (childElements[0].content_type === "TEXT") {
                            childSubsections.push({
                              type: childElements[0].content_type,
                              SubsectionID: currentChildSubsection.id,
                              heading_Level: childSubsectionLevel,
                              ElementID: childElements[0].id,
                              subSection: currentChildSubsection,
                              isNumberDisplay: JSON.parse(
                                childElements[childElements.length - 1]
                                  .content_format
                              ).isNumberDisplay,
                            });
                          }
                        } else {
                          return childSubsections;
                        }
                      }
                    }
                  }
                }
              }
            }
          );
        }
        parentLevelFound = true;
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    childSubsections;
    throw error;
  }

  return childSubsections;
};

// export const findChildSubSections = (
//   documentTemplate: DocumentTemplate,
//   section: DocumentSection,
//   subsection: DocumentSubsection
// ) => {
//   const childSubsections:any = [];
//   let parentLevelFound = false;
//   const subsectionID = subsection.id;
//   const sectionID = section.id;

//   try {
//     documentTemplate.sections?.forEach((currentSection) => {
//       if (currentSection.id === sectionID && !parentLevelFound) {
//         if (currentSection.subsections) {
//           currentSection.subsections.forEach((currentSubsection, subSectionIndex) => {
//             if (currentSubsection.id === subsectionID) {
//               const elements = currentSubsection.elements;
//               if (elements && elements.length > 0) {
//                 const subsectionLevel = elements[elements.length-1].heading_level;
//                 if(currentSection.subsections!=undefined){
//                 for (let i = subSectionIndex + 1; i < currentSection.subsections.length; i++) {
//                   const currentChildSubsection = currentSection.subsections[i];
//                   console.log(currentChildSubsection,"currentChildSubsection1234")
//                   const current = currentChildSubsection.elements?.map((element, index) => {
//                     if (element.edit === false) {
//                       return index;
//                     }
//                   }).filter(index => index !== undefined);

//                   const childElements = currentChildSubsection.elements;
//                   if (childElements && childElements.length > 0 && current!=undefined ) {

//                     const childSubsectionLevel = childElements[current].heading_level;
//                     console.log("childElements[childElements.length-1].heading_level" , childElements[childElements.length-1] , "currentChildSubsection " , currentChildSubsection )
//                     if (subsectionLevel < childSubsectionLevel ||( childElements[0].content_type==="TEXT" && childElements[0].heading_level===1)) {
//                       if(childElements[0].content_type==="HEADING"|| childElements[0].content_type==="TEXT"){
//                       childSubsections.push({
//                         type: childElements[0].content_type,
//                         SubsectionID: currentChildSubsection.id,
//                         heading_Level: childSubsectionLevel,
//                         ElementID: childElements[0].id,
//                         subSection:currentChildSubsection,
//                         isNumberDisplay:JSON.parse(childElements[childElements.length-1].content_format). isNumberDisplay
//                       });}
//                     } else {
//                       return childSubsections;
//                     }
//                   }
//                 }
//               }
//             }
//             }
//           });
//         }
//         parentLevelFound = true;
//       }
//     });
//   } catch (error) {
//     console.error("An error occurred:", error);
//     throw error;
//   }

//   return childSubsections;
// };

// todo? there is an error
export const findChildSubSections = (
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  checkCondition?: string
) => {
  const childSubsections: any = [];
  let parentLevelFound = false;
  const subsectionID = subsection.id;
  const sectionID = section.id;
  try {
    documentTemplate.sections?.forEach((currentSection) => {
      if (currentSection.id === sectionID && !parentLevelFound) {
        if (currentSection.subsections) {
          currentSection.subsections.forEach(
            (currentSubsection, subSectionIndex) => {
              if (currentSubsection.id === subsectionID) {
                const elements = currentSubsection.elements;
                if (elements && elements.length > 0) {
                  const subsectionLevel =
                    attachedSubsectionElement(currentSubsection).heading_level;

                  if (currentSection.subsections != undefined) {
                    for (
                      let i = subSectionIndex + 1;
                      i < currentSection.subsections.length;
                      i++
                    ) {
                      const currentChildSubsection =
                        currentSection.subsections[i];
                      let current = 0;
                      current = currentChildSubsection.elements
                        ? currentChildSubsection.elements.findIndex(
                            (element) => element.valid_until === null
                          )
                        : -1;
                      current = current >= 0 ? current : 0;
                      const childElements = currentChildSubsection.elements;
                      if (
                        childElements &&
                        childElements.length > 0 &&
                        current != undefined
                      ) {
                        const childSubsectionLevel =
                          childElements[current].heading_level;
                        if (
                          subsectionLevel < childSubsectionLevel ||
                          (childElements[0].content_type === "TEXT" &&
                            attachedSubsectionElement(currentChildSubsection)
                              .heading_level === 1)
                        ) {
                          if (
                            // i commented this
                            (childElements[0].content_type === "HEADING" ||
                              childElements[0].content_type === "TEXT") &&
                            currentChildSubsection.pending_deleted_at === null
                          ) {
                            childSubsections.push({
                              type: childElements[0].content_type,
                              SubsectionID: currentChildSubsection.id,
                              heading_Level: attachedSubsectionElement(
                                currentChildSubsection
                              ).heading_level,
                              // childSubsectionLevel,
                              ElementID: attachedSubsectionElement(
                                currentChildSubsection
                              ).id,
                              subSection: currentChildSubsection,
                              isNumberDisplay: JSON.parse(
                                childElements[current].content_format
                              ).isNumberDisplay,
                            });
                          }
                        }
                        // else if(checkCondition===childElements[0].content_type ){
                        //   return childSubsections
                        // }
                        //?? issue Resolved ?? i uncomment this  to reolve leveling issue
                        //  else {
                        //   return childSubsections;
                        // }
                      }
                    }
                  }
                }
              }
            }
          );
        }
        parentLevelFound = true;
      }
    });
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
  return childSubsections;
};

///? Deleted Add done
export const findParentSubHeading = (
  documentTemplate: DocumentTemplate,
  sectionID: number,
  subsectionID: any,
  componentOrder?: number
) => {
  let parentSubHeading = {};
  let parentLevelFound = false;

  try {
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            subSection.elements[0].content_type === "HEADING" &&
            !parentLevelFound &&
            subSection.pending_deleted_at === null
          ) {
            parentSubHeading = subSection;
          } else if (
            subSection.id === subsectionID &&
            !parentLevelFound
            // && subSection.component_order === componentOrder
          ) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);
    // You can throw the error or return a default value if needed
    throw error;
    // parentLevel = 1; // Example default value
  }
  return parentSubHeading as DocumentSubsection;
};

export const findParentTextElement = (
  documentTemplate: DocumentTemplate,
  sectionID: number,
  subsectionID: any
) => {
  let parentSubHeading = {};
  let parentLevelFound = false;
  try {
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            subSection.elements[0].content_type === "TEXT" &&
            !parentLevelFound
          ) {
            parentSubHeading = subSection;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);
    // You can throw the error or return a default value if needed
    throw error;
    // parentLevel = 1; // Example default value
  }

  return parentSubHeading as DocumentSubsection;
};

export const findParentofElement = (
  documentTemplate: DocumentTemplate,
  sectionID: number,
  subsectionID: any
) => {
  let parentSubHeading = {};
  let parentLevelFound = false;

  try {
    documentTemplate.sections?.forEach((section) => {
      if (section.id === sectionID && !parentLevelFound) {
        section.subsections?.forEach((subSection) => {
          if (
            subSection.id !== subsectionID &&
            subSection.elements &&
            subSection.elements[0] !== undefined &&
            subSection.elements[0].content_type === "TEXT" &&
            !parentLevelFound
          ) {
            parentSubHeading = subSection;
          } else if (subSection.id === subsectionID && !parentLevelFound) {
            parentLevelFound = true;
          }
        });
      }
    });
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);
    // You can throw the error or return a default value if needed
    throw error;
    // parentLevel = 1; // Example default value
  }
  return parentSubHeading as DocumentSubsection;
};

export const findAboveImageElement = (
  documentTemplate: DocumentTemplate,
  sectionID: number,
  aboveSubsection: DocumentSubsection | undefined,
  isSectionEmpty: boolean
) => {
  try {
    // let aboveImageSubSection: DocumentSubsection | null = null;
    let aboveImageContentFormat =
      '{"size":"Original","custom":100,"justify":"center","caption":"Image Caption","DisplyCaption":true,"captionOrder":"below","captionAlignment":"center","font":"arial","fontSize":"footnotesize","color":"000000","style":"bold","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}';
    let parentLevelFound = false;

    if (aboveSubsection && aboveSubsection.elements) {
      const firstElement = aboveSubsection.elements[0];
      if (firstElement.content_type === "IMAGE") {
        const updatedSubsection = getUpdatedSubsectionByID(
          documentTemplate,
          sectionID,
          aboveSubsection.id
        );

        if (updatedSubsection && updatedSubsection!.elements) {
          const parseData = JSON.parse(
            updatedSubsection!.elements[0].content_format
          );
          const result = {
            ...parseData,
            isDynamicImage: false,
            dynamicImageName: null,
            image_height: null,
            image_width: null,
          };
          console.log("A")
          return JSON.stringify(result);
        } else {
          const parseData = JSON.parse(firstElement.content_format);
          const result = {
            ...parseData,
            isDynamicImage: false,
            dynamicImageName: null,
            image_height: null,
            image_width: null,
          };
          console.log("B")
          return JSON.stringify(result);
        }
      }
    }

    if (aboveSubsection == undefined) {
      for (const section of documentTemplate.sections || []) {
        if (section.id === sectionID) {
          return aboveImageContentFormat;
        }
        section.subsections?.forEach((subSection) => {
          if (
            subSection.elements &&
            subSection.elements.length > 0 &&
            subSection.elements[0].content_type === "IMAGE" &&
            subSection.pending_deleted_at === null
          ) {
            aboveImageContentFormat = subSection.elements[0].content_format;
          }
        });
      }
    } else {
      for (const section of documentTemplate.sections || []) {
        if (!parentLevelFound) {
          for (const subSection of section.subsections || []) {
            if (subSection.id === aboveSubsection?.id) {
              parentLevelFound = true;
              return aboveImageContentFormat;
            } else if (
              subSection.elements &&
              subSection.elements.length > 0 &&
              subSection.pending_deleted_at === null
            ) {
              const firstElement = subSection.elements[0];
              if (firstElement.content_type === "IMAGE") {
                aboveImageContentFormat = firstElement.content_format;
              }
            }
          }
        }
      }
    }
    return aboveImageContentFormat;
  } catch (error) {
    // Handle the exception here
    console.error("An error occurred:", error);

    return '{"size":"Original","custom":100,"justify":"center","caption":"Image Caption","DisplyCaption":true,"captionOrder":"below","captionAlignment":"center","font":"arial","fontSize":"footnotesize","color":"000000","style":"bold","isBold":false,"isItalic":false,"isUnderline":false,"isStandard":true}';
    // You can throw the error or return a default value if needed
  }
};

// export function adjustChildLevels(
//   documentTemplate:DocumentTemplate,
//   section:DocumentSection,
//   subSection: DocumentSubsection,
//   updatingElement:DocumentElement
// ) {
//   const block = [] as DocumentSubsection[];
// documentTemplate.sections?.map((sectionMap)=>{
//   if(sectionMap.id===section.id){
//     sectionMap.subsections?.map((subSectionMap)=>{
//       if(subSectionMap.id===subSection.id){
//       // push all the below  subSectionMap to the block untill the  below subSectionMap.elements[0].heading_level<updatingElement.heading_level this condition is true
//       subSectionMap.elements[0].heading_level<updatingElement.heading_level
//       block.push(subSectionMap)
//       }
//     })
//   }

// })

//   return block

// }
